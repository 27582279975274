// User reducer types.
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const SET_USER_PERMISSION = "SET_USER_PERMISSION";

// Audio controls.
export const PLAY_AUDIO = "PLAY_AUDIO";
export const SET_AUDIO = "SET_AUDIO";
export const PAUSE_AUDIO = "PAUSE_AUDIO";
export const SET_PLAYING_TRACK = "SET_PLAYING_TRACK";
export const CLEAR_PLAYER = "CLEAR_PLAYER";
export const SET_PLAY_PROGRESS = "SET_PLAY_PROGRESS";
export const SET_BUFFER_PROGRESS = "SET_BUFFER_PROGRESS";
export const SET_PLAYER_VOLUME = "SET_PLAYER_VOLUME";
export const MUTE_PLAYER = "MUTE_PLAYER";
export const UNMUTE_PLAYER = "UNMUTE_PLAYER";
export const LOOP_PLAY = "LOOP_PLAY";
export const UNLOOP_PLAY = "UNLOOP_PLAY";
export const END_OF_AUDIO = "END_OF_AUDIO";
export const AUDIO_ERROR = "AUDIO_ERROR";
export const ASSET_LOADING = "ASSET_LOADING";
export const SET_PLAY_OBJECT = "SET_PLAY_OBJECT";
export const SET_PLAYER_MESSAGE = "SET_PLAYER_MESSAGE";
export const SET_POPOUT_FOR = "SET_POPOUT_FOR";
export const ABUSE_MSG_OPEN = "ABUSE_MSG_OPEN";
export const ABUSE_MSG_CLOSE = "ABUSE_MSG_CLOSE";

export const UPDATE_DOWNLOAD_LIST = "UPDATE_DOWNLOAD_LIST";
export const ADD_DOWNLOADING_LIST = "ADD_DOWNLOADING_LIST";
export const REMOVE_DOWNLOADING_LIST = "REMOVE_DOWNLOADING_LIST";
export const CLEAR_DOWNLOAD_LIST = "CLEAR_DOWNLOAD_LIST";

// UI reducer types.
export const SET_ERRORS = "SET_ERRORS";
export const POSTING_UI = "POSTING_UI";
export const STOP_POSTING_UI = "POSTING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const LOADING_DATA = "LOADING_DATA";
export const LOADING_RECENT_PL = "LOADING_RECENT_PL";
export const STOP_LOADING_RECENT_PL = "STOP_LOADING_RECENT_PL";
export const SET_ERRORS_RECENT_PL = "SET_ERRORS_RECENT_PL";
export const CLEAR_ERRORS_RECENT_PL = "CLEAR_ERRORS_RECENT_PL";
export const SET_TABLE_HEADER_HEIGHT = "SET_TABLE_HEADER_HEIGHT";

// Data reducer types.
export const SET_MAINPAGE_ITEMS = "SET_MAINPAGE_ITEMS";
export const APPEND_MAINPAGE_ITEMS = "APPEND_MAINPAGE_ITEMS";
export const SET_MAINPAGE_SELECTED_ITEM = "SET_MAINPAGE_SELECTED_ITEM";
export const SET_MAINPAGE_SELECTED_ITEMS = "SET_MAINPAGE_SELECTED_ITEMS";
export const UNSET_MAINPAGE_ITEMS = "UNSET_MAINPAGE_ITEMS";
export const UNSET_MAINPAGE_SELECTED_ITEM = "UNSET_MAINPAGE_SELECTED_ITEM";
export const UNSET_MAINPAGE_SELECTED_ITEMS = "UNSET_MAINPAGE_SELECTED_ITEMS";
export const SET_RECENT_PL = "SET_RECENT_PL";
export const POST_ITEM = "POST_ITEM";
export const POST_PL = "POST_PL";

// Inbox reducer.
export const INBOX_START_LOADING_ALL_USERS = "INBOX_START_LOADING_ALL_USERS";
export const INBOX_SET_ALL_USERS = "INBOX_SET_ALL_USERS";
export const INBOX_STOP_LOADING_ALL_USERS = "INBOX_STOP_LOADING_ALL_USERS";
export const INBOX_SET_ERROR_LOADING_ALL_USERS =
  "INBOX_SET_ERROR_LOADING_ALL_USERS";
export const INBOX_CLEAR_ERROR_LOADING_ALL_USERS =
  "INBOX_CLEAR_ERROR_LOADING_ALL_USERS";
export const INBOX_START_LOADING_ALL_GROUPS = "INBOX_START_LOADING_ALL_GROUPS";
export const INBOX_SET_ALL_GROUPS = "INBOX_SET_ALL_GROUPS";
export const INBOX_STOP_LOADING_ALL_GROUPS = "INBOX_STOP_LOADING_ALL_GROUPS";
export const INBOX_SET_ERROR_LOADING_ALL_GROUPS =
  "INBOX_SET_ERROR_LOADING_ALL_GROUPS";
export const INBOX_CLEAR_ERROR_LOADING_ALL_GROUPS =
  "INBOX_CLEAR_ERROR_LOADING_ALL_GROUPS";
export const INBOX_START_LOADING_MESSAGES = "INBOX_START_LOADING_MESSAGES";
export const INBOX_SET_MESSAGES = "INBOX_SET_MESSAGES";
export const INBOX_STOP_LOADING_MESSAGES = "INBOX_STOP_LOADING_MESSAGES";
export const INBOX_SET_ERROR_LOADING_MESSAGES =
  "INBOX_SET_ERROR_LOADING_MESSAGES";
export const INBOX_CLEAR_ERROR_LOADING_MESSAGES =
  "INBOX_CLEAR_ERROR_LOADING_MESSAGES";
export const INBOX_SEND_MESSAGE = "INBOX_SEND_MESSAGE";
export const INBOX_ARCHIVE_MESSAGE = "INBOX_ARCHIVE_MESSAGE";
export const INBOX_UNARCHIVE_MESSAGE = "INBOX_UNARCHIVE_MESSAGE";
export const INBOX_SAVE_DRAFT_MESSAGE = "INBOX_SAVE_DRAFT_MESSAGE";
export const INBOX_START_LOADING_COMMENTS = "INBOX_START_LOADING_COMMENTS";
export const INBOX_SET_COMMENTS = "INBOX_SET_COMMENTS";
export const INBOX_STOP_LOADING_COMMENTS = "INBOX_STOP_LOADING_COMMENTS";
export const INBOX_SET_ERROR_LOADING_COMMENTS =
  "INBOX_SET_ERROR_LOADING_COMMENTS";
export const INBOX_CLEAR_ERROR_LOADING_COMMENTS =
  "INBOX_CLEAR_ERROR_LOADING_COMMENTS";
export const INBOX_SEND_COMMENT = "INBOX_SEND_COMMENT";
export const INBOX_ARCHIVE_COMMENT = "INBOX_ARCHIVE_COMMENT";
export const INBOX_UNARCHIVE_COMMENT = "INBOX_UNARCHIVE_COMMENT";
export const INBOX_SAVE_DRAFT_COMMENT = "INBOX_SAVE_DRAFT_COMMENT";
export const INBOX_START_LOADING_USERS = "INBOX_START_LOADING_USERS";
export const INBOX_SET_USERS = "INBOX_SET_USERS";
export const INBOX_STOP_LOADING_USERS = "INBOX_STOP_LOADING_USERS";
export const INBOX_SET_ERROR_LOADING_USERS = "INBOX_SET_ERROR_LOADING_USERS";
export const INBOX_CLEAR_ERROR_LOADING_USERS =
  "INBOX_CLEAR_ERROR_LOADING_USERS";
export const INBOX_SAVE_CONTACT = "INBOX_SAVE_CONTACT";
export const INBOX_DELETE_CONTACT = "INBOX_DELETE_CONTACT";

export const SET_LIBRARY_SEARCH_CONFIG = "SET_LIBRARY_SEARCH_CONFIG";

export const OriginTypes = {
  all: "all",
  commercial: "commercial",
  library: "library",
  libraryMcps: "libraryMcps",
  libraryNonMcps: "libraryNonMcps",
  commissioned: "commissioned",
  live: "live",
};

export const SubOriginTypes = {
  Mcps: "MCPS",
  NonMcps: "Non-MCPS",
};

export const userSysStatus = {
  CREATED: 0,
  INVITED: 1,
  ACTIVE: 2,
  ARCHIVED: 3,
};

export const SmSysAdminOrgStatusTypes = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const booleanType = {
  TRUE: "true",
  FALSE: "false",
};

export const userOrgStatus = {
  CREATED: 0,
  INVITED: 1,
  ACTIVE: 2,
  ARCHIVED: 3,
};

export const helpOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const userOrgBBCSSO = {
  YES: 1,
  NO: 0,
};

export const roleOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const roleOrgInbuilt = {
  NO: 0,
  YES: 1,
};

export const settingOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const settingOrgInbuilt = {
  NO: 0,
  YES: 1,
};

export const reportStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const reportFileStatus = {
  TOBEGENERATED: 0,
  COMPLETED: 1,
  ERROR: 2,
};

export const ruleOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const paramOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const statusOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

// export const clearanceFormOrgStatus = {
//   ACTIVE: 0,
//   ARCHIVED: 1,
//   INPROGRESS: 2,
//   COMPLETED: 3,
//   REFERRED:4,
//   REVIEWED:5,
// };

export const CFOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
  INPROGRESS: 2,
  COMPLETED: 3,
  REFERRED:4,
  REVIEWED:5,
  SUBMITTED: 6,
  WITHDRAWN:7,
};

export const CFOrgStatusLabel = [
  {label : "Active" , color : "#dbbf63"},
  {label : "Archived" , color : "#8e5252"},
  {label : "Draft" , color : "#2cd5ff"},
  {label : "Complete" , color : "#c900fb"},
  {label : "Referred" , color : "#fb9e00"},
  {label : "In Review" , color : "#cad888"},
  {label : "Submitted" , color : "#fb9e00"},
  {label : "Recalled" , color : "#00bfa0"}

]

export const groupOrgStatus = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

export const playListCatOrgStatus = {
  ACTIVE: "1",
  ARCHIVED: "2",
};

export const userPrefType = {
  SERACHPREF: 1,
  ALBUMSEARCHPREF: 2,
  CLERANCEINBOXPREF: 3,
  CLERANCEFORMSPREF: 4,
};

export const logUserActivity = {
    DO_NOT_LOG: -1,
    OTHER: 0,
    SYSTEM_LOGIN: 1,
    SYSTEM_LOGIN_FAILED: 19,
    SYSTEM_TWOFA: 78,
    SYSTEM_TWOFA_FAILED: 80,
    SYSTEM_LOGOUT: 79,

    SEARCH_TRACK: 2,
    SEARCH_ALBUM: 18,
    STREAM_TRACK: 5,
    STREAM_TRACK_RESTRICTED: 57,

    PLAYLIST_ADD_TRACK: 7,
    PLAYLIST_CREATE: 11,
    PLAYLIST_UPDATE: 12,
    PLAYLIST_ADD_USERS: 13,
    PLAYLIST_REM_TRACK: 14,
    PLAYLIST_REM_USERS: 15,
    PLAYLIST_CREATE_ADD_TRACK: 39,
    PLAYLIST_ARCHIVE: 65,
    PLAYLIST_RESTORE: 66,

    USER_DETAIL_UPDATE: 20,
    USER_RESTORE: 21,
    USER_ARCHIVE: 22,
    USER_INVITE: 23,
    USER_CREATE: 24,
    USER_SIGNUP: 38,
    USER_SIGNUP_SSO: 77,
    USER_ACCOUNT_EXPIRATION: 71,
    
    ROLE_DETAIL_UPDATE: 25,
    ROLE_RESTORE: 26,
    ROLE_ARCHIVE: 27,
    ROLE_CREATE: 28,

    ABUSE_RULE_CREATE: 58,
    ABUSE_RULE_UPDATE: 59,
    ABUSE_RULE_RESTORE: 60,
    ABUSE_RULE_ARCHIVE: 61,

    DOWNLOAD_LOW_RES: 29,
    DOWNLOAD_HI_RES: 30,
    DOWNLOAD_LOW_RES_RESTRICTED: 55,
    DOWNLOAD_HI_RES_RESTRICTED: 56,

    CF_ARCHIVE: 16,
    CF_RESTORE: 17,
    CF_ADD_TRACK: 31,
    CF_RUN_CLEARANCE: 32,
    CF_RUN_CLEARANCE_IN_PROGRESS: 75,
    CF_CLEARANCE_STATUS_OVERRIDE: 33,
    CF_REM_TRACK: 34,
    // CF_CLEARANCE_LIVE_COPY: 54,
    CF_CREATE_FORM: 67,
    CF_CREATE_FORM_AND_ADD_TRACKS: 68,
    CF_UPDATE_FORM: 70,
    CF_SAVEFORM: 69,    // Internal
    CF_CHANGE_STATUS: 74, 

    SETTING_UPDATE: 35,
    SETTING_ARCHIVE: 36,
    SETTING_RESTORE: 37,

    ABUSE_FLAG_SET: 40,
    ABUSE_FLAG_REMOVE: 52,
    ABUSE_FLAG_REMOVE_AUTOMATIC: 53,

    WORKSPACE_RESYNC: 41,
    WORKSPACE_TAKEDOWN: 42,
    WORKSPACE_SET_LIVE: 43,

    LIBRARY_TAKEDOWN: 44,
    LIBRARY_SET_LIVE: 45,

    CTAG_ADD: 46,
    CTAG_UPDATE: 47,
    CTAG_EXTENDED_ADD: 48,
    CTAG_EXTENDED_UPDATE: 49,

    PPL_LABEL_ADD: 50,
    PPL_LABEL_UPDATE: 51,

    MESSAGE_SEND: 62,   // Generic for all the msgs
    MESSAGE_CONTENT_REQUEST_TRACK: 63,
    MESSAGE_CONTENT_REQUEST_ALBUM: 64,
    MESSAGE_CONTENT_REQUEST_CHANGE_STATUS: 73,
    
    PLAYOUT_PUBLISH: 72,
    PLAYOUT_ADD_TRACK: 76
}

export const logUserActivityA = [
  { id: logUserActivity.SYSTEM_LOGIN, label: "User Login" },
  { id: logUserActivity.SYSTEM_LOGIN_FAILED, label: "User Login Failed" },
  { id: logUserActivity.SYSTEM_TWOFA, label: "User 2FA New Device Login" },
  { id: logUserActivity.SYSTEM_TWOFA_FAILED, label: "User 2FA New Device Login Failed" },
  { id: logUserActivity.SYSTEM_LOGOUT, label: "User Logout" },
  { id: logUserActivity.USER_SIGNUP, label: "User Sign-up" },
  { id: logUserActivity.USER_SIGNUP_SSO, label: "User SSO Sign-up" },
  { id: logUserActivity.USER_DETAIL_UPDATE, label: "User Update" },
  { id: logUserActivity.USER_RESTORE, label: "User Restore" },
  { id: logUserActivity.USER_ARCHIVE, label: "User Archive" },
  { id: logUserActivity.USER_INVITE, label: "User Invite" },
  { id: logUserActivity.USER_CREATE, label: "User Create" },
  { id: logUserActivity.USER_ACCOUNT_EXPIRATION, label: "User Account Expiration" },
  { id: logUserActivity.ROLE_DETAIL_UPDATE, label: "Role Update" },
  { id: logUserActivity.ROLE_RESTORE, label: "Role Restore" },
  { id: logUserActivity.ROLE_ARCHIVE, label: "Role Archive" },
  { id: logUserActivity.ROLE_CREATE, label: "Role Create" },
  { id: logUserActivity.SETTING_UPDATE, label: "Setting Update" },
  { id: logUserActivity.SETTING_ARCHIVE, label: "Setting Archive" },
  { id: logUserActivity.SETTING_RESTORE, label: "Setting Restore" },
  { id: logUserActivity.ABUSE_RULE_CREATE, label: "Activity Limitation - Create Rule" },
  { id: logUserActivity.ABUSE_RULE_UPDATE, label: "Activity Limitation - Update Rule" },
  { id: logUserActivity.ABUSE_RULE_RESTORE, label: "Activity Limitation - Restore Rule" },
  { id: logUserActivity.ABUSE_RULE_ARCHIVE, label: "Activity Limitation - Archive Rule" },

  { id: logUserActivity.SEARCH_TRACK, label: "Track Search" },
  { id: logUserActivity.SEARCH_ALBUM, label: "Album Search" },
  { id: logUserActivity.STREAM_TRACK, label: "Track Stream" },
  { id: logUserActivity.STREAM_TRACK_RESTRICTED, label: "Track Stream Restricted" },
  { id: logUserActivity.DOWNLOAD_LOW_RES, label: "Track Download Low-res" },
  { id: logUserActivity.DOWNLOAD_LOW_RES_RESTRICTED, label: "Track Download Low-res Restricted" },
  { id: logUserActivity.DOWNLOAD_HI_RES, label: "Track Download Hi-res" },
  { id: logUserActivity.DOWNLOAD_HI_RES_RESTRICTED, label: "Track Download Hi-res Restricted" },

  { id: logUserActivity.PLAYLIST_ADD_TRACK, label: "Playlist Add Track" },
  { id: logUserActivity.PLAYLIST_CREATE, label: "Playlist Create" },
  { id: logUserActivity.PLAYLIST_UPDATE, label: "Playlist Update" },
  { id: logUserActivity.PLAYLIST_ADD_USERS, label: "Playlist Share" },
  { id: logUserActivity.PLAYLIST_REM_TRACK, label: "Playlist Remove Track" },
  { id: logUserActivity.PLAYLIST_REM_USERS, label: "Playlist Remove Users" },
  { id: logUserActivity.PLAYLIST_ARCHIVE, label: "Playlist Archive" },
  { id: logUserActivity.PLAYLIST_RESTORE, label: "Playlist Restore" },

  { id: logUserActivity.CF_ARCHIVE, label: "Clearance Form Archive" },
  { id: logUserActivity.CF_RESTORE, label: "Clearance Form Restore" },
  { id: logUserActivity.CF_CREATE_FORM, label: "Clearance Form Create" },
  { id: logUserActivity.CF_UPDATE_FORM, label: "Clearance Form Update" },
  { id: logUserActivity.CF_CREATE_FORM_AND_ADD_TRACKS, label: "Clearance Form Create and Add Tracks" },
  { id: logUserActivity.CF_ADD_TRACK, label: "Clearance Form Add Track" },
  { id: logUserActivity.CF_REM_TRACK, label: "Clearance Form Remove Track" },
  { id: logUserActivity.CF_RUN_CLEARANCE_IN_PROGRESS, label: "Clearance - In progress" },
  { id: logUserActivity.CF_RUN_CLEARANCE, label: "Clearance - Run Clearance" },
  { id: logUserActivity.CF_CLEARANCE_STATUS_OVERRIDE, label: "Clearance Status Override" },
  // { id: logUserActivity.CF_CLEARANCE_LIVE_COPY, label: "Clearance Live Version" },
  { id: logUserActivity.CF_CHANGE_STATUS, label: "Clearance Form Change Status" },

  { id: logUserActivity.ABUSE_FLAG_SET, label: "Abuse Flag Set" },
  { id: logUserActivity.ABUSE_FLAG_REMOVE, label: "Abuse Flag Remove" },
  { id: logUserActivity.ABUSE_FLAG_REMOVE_AUTOMATIC, label: "Abuse Flag Remove Automatic" },

  { id: logUserActivity.WORKSPACE_RESYNC, label: "Workspace Resync" },
  { id: logUserActivity.WORKSPACE_TAKEDOWN, label: "Workspace Archive" },
  { id: logUserActivity.WORKSPACE_SET_LIVE, label: "Workspace Set Live" },

  { id: logUserActivity.LIBRARY_TAKEDOWN, label: "Library Archive" },
  { id: logUserActivity.LIBRARY_SET_LIVE, label: "Library Set Live" },

  { id: logUserActivity.CTAG_ADD, label: "CTAG Create" },
  { id: logUserActivity.CTAG_UPDATE, label: "CTAG Edit" },
  { id: logUserActivity.CTAG_EXTENDED_ADD, label: "CTAG Rule Create" },
  { id: logUserActivity.CTAG_EXTENDED_UPDATE, label: "CTAG Rule Edit" },
  { id: logUserActivity.PPL_LABEL_ADD, label: "PPL Label Create" },
  { id: logUserActivity.PPL_LABEL_UPDATE, label: "PPL Label Edit" },

  { id: logUserActivity.MESSAGE_CONTENT_REQUEST_TRACK, label: "Content Request - Track" },
  { id: logUserActivity.MESSAGE_CONTENT_REQUEST_ALBUM, label: "Content Request - Album" },
  { id: logUserActivity.MESSAGE_CONTENT_REQUEST_CHANGE_STATUS, label: "Content Request - Change Status" },

  { id: logUserActivity.PLAYOUT_PUBLISH, label: "Playout - Publish to Playout" },
  { id: logUserActivity.PLAYOUT_ADD_TRACK, label: "Playout - Add to Playout" },
];

export const activitiesPreventAbuseA = [
  { id: logUserActivity.STREAM_TRACK, label: "Streams" },
  { id: logUserActivity.STREAM_TRACK_RESTRICTED, label: "Streams Restricted Tracks" },
  { id: logUserActivity.DOWNLOAD_HI_RES, label: "Hi-res Downloads" },
  { id: logUserActivity.DOWNLOAD_HI_RES_RESTRICTED, label: "Hi-res Downloads Restricted Tracks" },
  { id: logUserActivity.DOWNLOAD_LOW_RES, label: "Low-res Downloads" },
  { id: logUserActivity.DOWNLOAD_LOW_RES_RESTRICTED, label: "Low-res Downloads Restricted Tracks" },
];

export const userOrgAbuseFlag = {
  DEACTIVATED: "0",
  LOGGEDOUT: "1",
  WARNED: "2",
};
export const userOrgAbuseFlagA = [
  { id: userOrgAbuseFlag.DEACTIVATED, label: "DEACTIVATED" },
  { id: userOrgAbuseFlag.LOGGEDOUT, label: "LOGGED OUT" },
  { id: userOrgAbuseFlag.WARNED, label: "WARNED" },
];


export const ruleOutcomeTypes = {
  DEACTIVATE: 0,
  LOGOUT: 1,
  WARN: 2
}

export const ruleOutcomeTypesA = [
  { id: ruleOutcomeTypes.DEACTIVATE, label: "Deactivate user" },
  { id: ruleOutcomeTypes.LOGOUT, label: "Logout user" },
  { id: ruleOutcomeTypes.WARN, label: "Warn user" },
];

export const ruleOutcomeTypesUserWillBeA = [
  { id: ruleOutcomeTypes.DEACTIVATE, label: "Deactivated" },
  { id: ruleOutcomeTypes.LOGOUT, label: "Logged out" },
  { id: ruleOutcomeTypes.WARN, label: "Warned" },
];

const ruleUnitTime = {
  MINUTE: 0,
  HOUR: 1,
  DAY: 2,
  MONTH: 3,
};
export const ruleUnitTimeA = [
  { id: ruleUnitTime.MINUTE, label: "Minutes" },
  { id: ruleUnitTime.HOUR, label: "Hours" },
  { id: ruleUnitTime.DAY, label: "Days" },
  { id: ruleUnitTime.MONTH, label: "Months" },
];


export const accessPeriodTypesA = [
  // { id: 1, label: "5 minutes", periodInMinutes: 5 },
  // { id: 2, label: "1 hour", periodInMinutes: 60 },
  //{ id: 3, label: "1 day", periodInDays: 1 },
  { id: 10, label: "7 days", periodInDays: 7 },
  { id: 20, label: "1 month", periodInDays: 30 },
  { id: 30, label: "2 months", periodInDays: 60 },
  { id: 40, label: "6 months", periodInDays: 180 },
  { id: 50, label: "1 year", periodInDays: 365 },
  { id: 60, label: "2 years", periodInDays: 730 },
];


export const logUserObject = {
  UNKNOWN: 0,
  TRACK: 1,
  PLAYLIST: 2,
  CLEARANCE_FORM: 3,
  MESSAGE: 4,
  CLEAANCE_RULE: 5,
  ADMIN_CONFIG: 6,
  USER: 7,
  ROLE: 8,
  SETTINGS: 9,
};

export const logUserObjectA = [
  { id: logUserObject.TRACK, label: "Track" },
  { id: logUserObject.PLAYLIST, label: "Playlist" },
  { id: logUserObject.CLEARANCE_FORM, label: "Clearance Form" },
  { id: logUserObject.MESSAGE, label: "Message" },
  { id: logUserObject.CLEAANCE_RULE, label: "Clearance Rule" },
  { id: logUserObject.USER, label: "User" },
  { id: logUserObject.ROLE, label: "Role" },
  { id: logUserObject.ADMIN_CONFIG, label: "Admin Config" },
  { id: logUserObject.OTHER, label: "Other" },
  { id: logUserObject.SETTINGS, label: "Settings" },
];

export const textLengths = {
  SMALL: 10,
  TEXT: 256,
  MEDIUMTEXT: 500,
  LONGTEXT: 1000,
  INDICATOR: 6,
};

export const ContentSyncStatus = {
  ToBeSynced: 1,
  Inprogress: 2,
  Success: 3,
  Failed: 4,
};

export const ContentMLStatus = {
  Available: 1,
  Live: 2,
  Archived: 3,
};

export const PageTableType = {
  CLEARANCE_FORMS: 1,
  CLEARANCE_TRACKS: 2,
  ADMIN_USERS: 3,
  ADMIN_ROLES: 4,
  ADMIN_SETTINGS: 5,
  LICENCING_ADMIN_RULES: 6,
  LICENCING_ADMIN_STATUSES: 7,
  LICENCING_ADMIN_PARAMETERS: 8,
  ADMIN_PLAYLIST_CAT: 9,
  ADMIN_ACTIVITY_LIMIT: 10,
  SEARCH_TRACKS: 11,
  SEARCH_ALBUMS: 12,
  SEARCH_ALBUM_TRACKS: 13,
  MYPLAYLIST: 14,
  MYPLAYLIST_TRACKS: 15,
  PLAYLIST_PROMOS: 16,
  UPLOAD_TRACKS: 17,
  ACTIVITY_LOG: 18,
  REPORTS: 19,
  ADMIN_GROUPS: 20,
  CONTENT_ADMIN: 21,
  HELP_ADMIN: 22,
  CONTENT_REQUEST: 23,
  CONTENT_ALERT: 24,
  PLAYOUT: 25,
  MLCADMIN_WORKSPACES: 26,
  SAVED_SEARCH: 27,
  UPLOAD_SESSION: 28,
  UPLOAD_TRACK: 29,
  UPLOAD_ALBUM: 30,
  MLCADMIN_LIBRARIES: 31,
  MLCADMIN_CTAGRULES: 32,
  MLCADMIN_PPLLABELS: 33,
  MLCADMIN_PRIORAPPROVAL: 34,
  PLAYLIST_INBOX: 35,
  PLAYLIST_OUTBOX: 36,
  CONTENT_ADMIN_TRACKS: 37,
  SYSADMIN_APPLOGS: 38,
  PLAYOUT_TRACKS: 39,
  UPLOAD_SESSION_TRACKS: 40,
  UPLOAD_ALBUM_TRACKS: 41,
  SMADMIN_WORKSPACES: 42,
  SMADMIN_LIBRARIES: 43,
  SMADMIN_TRACKS: 44,
  MLCADMIN_CTAGS: 45,
  CLEARANCE_FORM_TRACKS: 46,
};

export const fieldType = {
  TEXT: 1,
  NUMBER: 2,
  DATE: 3,
  BOOLEAN: 4
};

export const pagingType = {
  PAGING: "paging",
  INFINITE: "infinite",
};

export const pagingTotalRelation = {
  EQUALS: "eq",
  GREATER_THAN: "gte",
};

export const resultsPerPage = [25, 50, 100, 200];
export const resultsPerPageAddtoAlbum = [10, 25, 50];

export const orderByTagCloud = ['count', 'terms'];

export const defaultContentResultsPerPage = 50;
export const defaultResultsPerPage = 25;
export const defaultPopupResults = 50;

// This type is also in CentralAPI, keep consistent if you modify it
export const groupType = {
  CLEARANCE: 1,
  CONTENT: 2,
  MESSAGING: 3,
}
// This type is also in CentralAPI, keep consistent if you modify it
export const groupTypes = [
  { id: groupType.CLEARANCE, label: "Clearance Group" },
  { id: groupType.CONTENT, label: "Content Group" },
  { id: groupType.MESSAGING, label: "Messaging Group" },
]

export const recipientGroupType = {
  USER: "Users",
  GROUP: "Groups"
}

export const defaultGroups = {
  CLEARANCE_GROUP: "24",
  CONTENT_GROUP: "25",
}

export const CFStatusActions = [
  {
    id:CFOrgStatus.INPROGRESS, 
    label:"Draft", 
  },
  // {
  //   id:CFOrgStatus.REFERRED, 
  //   label:"Referred", 
  // },
  {
    id:CFOrgStatus.SUBMITTED, 
    label:"Submitted", 
  },
  {
    id:CFOrgStatus.REVIEWED, 
    label:"In Review", 
  }, 
  {
    id:CFOrgStatus.COMPLETED, 
    label:"Complete", 
  },
  // {
  //   id:CFOrgStatus.ARCHIVED, 
  //   label:"Archived", 
  // },
  {
    id:CFOrgStatus.WITHDRAWN, 
    label:"Recalled", 
  },
]

export const MlAdminRoutePermission = {
  MANAGE_USERS_ALLOWED: 1,
  MLADMIN_ALLOWED: 2, 
  NO_SYSADMIN_NO_MLADMIN:3
}