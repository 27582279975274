import { textLengths } from "../../../redux/Types";

export const validateEditTrack = (data) => {
    let errors = {};
    if (data && data.trackMetadata) {
        //Number Validations
        const regex = new RegExp('^[0-9]+$');
        if (data.albumMetadata && data.albumMetadata.album_discs) {
            if (!regex.test(data.albumMetadata.album_discs)) {
                errors.album_discs = "Album Discs should be a valid number.";
            }
        }
        if (data.trackMetadata.position && !regex.test(data.trackMetadata.position)) {
            errors.position = "Position should be a valid number.";
        }

        //Track Data Validation
        if (!data.trackMetadata.track_title && !data.trackMetadata.track_title.length) {
            errors.track_title = "Title should not be empty.";
        }
        
        if (data.trackMetadata.track_title && data.trackMetadata.track_title.length > textLengths.TEXT) {
            errors.track_title = "Title should be less than 256 characters.";
        }
        if (data.trackMetadata.alternate_title && data.trackMetadata.alternate_title.length > textLengths.TEXT) {
            errors.alternate_title = "Alternate Title should be less than 256 characters.";
        }
        if (data.trackMetadata.version_title && data.trackMetadata.version_title.length > textLengths.TEXT) {
            errors.version_title = "Version Title should be less than 256 characters.";
        }
        if (data.trackMetadata.isrc && data.trackMetadata.isrc.length > 32) {
            errors.isrc = "ISRC should be less than 33 characters.";
        }
        if (data.trackMetadata.rec_label && data.trackMetadata.rec_label.length > textLengths.TEXT) {
            errors.rec_label = "Record label should be less than 256 characters.";
        }
        if (data.trackMetadata.iswc && data.trackMetadata.iswc.length > 32) {
            errors.iswc = "ISWC should be less than 33 characters.";
        }
        if (data.trackMetadata.pLine && data.trackMetadata.pLine.length > textLengths.TEXT) {
            errors.pLine = "PLine should be less than 256 characters.";
        }
        if (data.trackMetadata.bpm && data.trackMetadata.bpm.length > textLengths.TEXT) {
            errors.bpm = "BPM should be less than 256 characters.";
        }
        if (data.trackMetadata.tempo && data.trackMetadata.tempo.length > textLengths.TEXT) {
            errors.tempo = "Tempo should be less than 256 characters.";
        }
        if (data.trackMetadata.track_notes && data.trackMetadata.track_notes.length > textLengths.TEXT) {
            errors.track_notes = "Track Notes should be less than 256 characters.";
        }
        if (data.trackMetadata.bbc_track_id && data.trackMetadata.bbc_track_id.length > textLengths.TEXT) {
            errors.bbc_track_id = `${process.env.REACT_APP_CLIENT_NAME?process.env.REACT_APP_CLIENT_NAME:"BBC"} Track Barcode should be less than 256 characters.`;
        }
        if (data.trackMetadata.org_admin_notes && data.trackMetadata.org_admin_notes.length > textLengths.LONGTEXT) {
            errors.org_admin_notes = "Admin Notes should be less than 1000 characters.";
        }
        if (data.trackMetadata.prs && data.trackMetadata.prs.length > 32) {
            errors.prs = "Datahub PRS should be less than 33 characters.";
        }
        if (data.trackMetadata.prs_work_tunecode && data.trackMetadata.prs_work_tunecode.length > 32) {
            errors.prs_work_tunecode = "External Prs Tune Code should be less than 33 characters.";
        }
        if (data.trackMetadata.prs_work_title && data.trackMetadata.prs_work_title.length > textLengths.TEXT) {
            errors.prs_work_title = "External Prs Work Title should be less than 256 characters.";
        }
        if (data.trackMetadata.prs_work_writers && data.trackMetadata.prs_work_writers.length > textLengths.TEXT) {
            errors.prs_work_writers = "External Prs Writers should be less than 256 characters.";
        }
        if (data.trackMetadata.prs_work_publishers && data.trackMetadata.prs_work_publishers.length > textLengths.TEXT) {
            errors.prs_work_publishers = "External Prs Publishers should be less than 256 characters.";
        }
        if (data.trackMetadata.performers && data.trackMetadata.performers.length > 0) {
            data.trackMetadata.performers.map((performer) => {
                if (performer && performer.length > textLengths.TEXT) {
                    errors.performers = "Performers should be less than 256 characters.";
                }
            })
        }
        if (data.trackMetadata.arrangers && data.trackMetadata.arrangers.length > 0) {
            data.trackMetadata.arrangers.map((arranger) => {
                if (arranger && arranger.length > textLengths.TEXT) {
                    errors.arrangers = "Arrangers should be less than 256 characters.";
                }
            })
        }
        if (data.trackMetadata.publishers && data.trackMetadata.publishers.length > 0) {
            data.trackMetadata.publishers.map((publisher) => {
                if (publisher && publisher.length > textLengths.TEXT) {
                    errors.publishers = "Publishers should be less than 256 characters.";
                }
            })
        }
        if (data.trackMetadata.lyricist && data.trackMetadata.lyricist.length > 0) {
            data.trackMetadata.lyricist.map((lyricists) => {
                if (lyricists && lyricists.length > textLengths.TEXT) {
                    errors.lyricist = "Lyricist should be less than 256 characters.";
                }
            })
        }
        if (data.trackMetadata.composer_lyricists && data.trackMetadata.composer_lyricists.length > 0) {
            data.trackMetadata.composer_lyricists.map((composer_lyricist) => {
                if (composer_lyricist && composer_lyricist.length > textLengths.TEXT) {
                    errors.composer_lyricists = "Composer Lyricists should be less than 256 characters.";
                }
            })
        }
        if (data.trackMetadata.composers && data.trackMetadata.composers.length > 0) {
            data.trackMetadata.composers.map((composer) => {
                if (composer && composer.length > textLengths.TEXT) {
                    errors.composers = "Composers should be less than 256 characters.";
                }
            })
        }
        if (data.trackMetadata.translators && data.trackMetadata.translators.length > 0) {
            data.trackMetadata.translators.map((translator) => {
                if (translator && translator.length > textLengths.TEXT) {
                    errors.translators = "Translators should be less than 256 characters.";
                }
            })
        }

        //TAGS
        if (data.trackMetadata.genres && data.trackMetadata.genres.length > 0) {
            data.trackMetadata.genres.map((genre) => {
                if (genre && genre.length > textLengths.LONGTEXT) {
                    errors.genres = "Genres should be less than 1000 characters.";
                }
            })
        }
        if (data.trackMetadata.styles && data.trackMetadata.styles.length > 0) {
            data.trackMetadata.styles.map((style) => {
                if (style && style.length > textLengths.LONGTEXT) {
                    errors.styles = "Styles should be less than 1000 characters.";
                }
            })
        }
        if (data.trackMetadata.moods && data.trackMetadata.moods.length > 0) {
            data.trackMetadata.moods.map((mood) => {
                if (mood && mood.length > textLengths.LONGTEXT) {
                    errors.moods = "Moods should be less than 1000 characters.";
                }
            })
        }
        if (data.trackMetadata.keywords && data.trackMetadata.keywords.length > 0) {
            data.trackMetadata.keywords.map((keyword) => {
                if (keyword && keyword.length > textLengths.LONGTEXT) {
                    errors.keywords = "Keywords should be less than 1000 characters.";
                }
            })
        }
        if (data.trackMetadata.instruments && data.trackMetadata.instruments.length > 0) {
            data.trackMetadata.instruments.map((instrument) => {
                if (instrument && instrument.length > textLengths.LONGTEXT) {
                    errors.instruments = "Instruments should be less than 1000 characters.";
                }
            })
        }
        if (data.trackMetadata.org_adminTags && data.trackMetadata.org_adminTags.length > 0) {
            data.trackMetadata.org_adminTags.map((admin_tag) => {
                if (admin_tag && admin_tag.length > 32) {
                    errors.org_adminTags = "Tags should be less than 33 characters.";
                }
            })
        }

        if (data.trackMetadata.contributor_featured_artist && data.trackMetadata.contributor_featured_artist.length > 0) {
            data.trackMetadata.contributor_featured_artist.map((contributor_featured_artistx) => {
                if (contributor_featured_artistx && contributor_featured_artistx.length > textLengths.MEDIUMTEXT) {
                    errors.contributor_featured_artist = "Featured Artist should be less than 500 characters.";
                }
            })
        }
        if (data.trackMetadata.contributor_remix_artist && data.trackMetadata.contributor_remix_artist.length > 0) {
            data.trackMetadata.contributor_remix_artist.map((contributor_remix_artistx) => {
                if (contributor_remix_artistx && contributor_remix_artistx.length > textLengths.MEDIUMTEXT) {
                    errors.contributor_remix_artist = "Contributor Remix Artist should be less than 500 characters.";
                }
            })
        }
        if (data.trackMetadata.contributor_versus_artist && data.trackMetadata.contributor_versus_artist.length > 0) {
            data.trackMetadata.contributor_versus_artist.map((contributor_versus_artistx) => {
                if (contributor_versus_artistx && contributor_versus_artistx.length > textLengths.MEDIUMTEXT) {
                    errors.contributor_versus_artist = "Contributor Versus Artist should be less than 500 characters.";
                }
            })
        }
        if (data.trackMetadata.contributor_conductor && data.trackMetadata.contributor_conductor.length > 0) {
            data.trackMetadata.contributor_conductor.map((contributor_conductorx) => {
                if (contributor_conductorx && contributor_conductorx.length > textLengths.MEDIUMTEXT) {
                    errors.contributor_conductor = "Contributor Conductor should be less than 500 characters.";
                }
            })
        }
        if (data.trackMetadata.contributor_choir && data.trackMetadata.contributor_choir.length > 0) {
            data.trackMetadata.contributor_choir.map((contributor_choirx) => {
                if (contributor_choirx && contributor_choirx.length > textLengths.MEDIUMTEXT) {
                    errors.contributor_choir = "Contributor Choir should be less than 500 characters.";
                }
            })
        }
        if (data.trackMetadata.contributor_ensemble && data.trackMetadata.contributor_ensemble.length > 0) {
            data.trackMetadata.contributor_ensemble.map((contributor_ensemblex) => {
                if (contributor_ensemblex && contributor_ensemblex.length > textLengths.MEDIUMTEXT) {
                    errors.contributor_ensemble = "Contributor Ensemble should be less than 500 characters.";
                }
            })
        }
        if (data.trackMetadata.contributor_orchestra && data.trackMetadata.contributor_orchestra.length > 0) {
            data.trackMetadata.contributor_orchestra.map((contributor_orchestrax) => {
                if (contributor_orchestrax && contributor_orchestrax.length > textLengths.MEDIUMTEXT) {
                    errors.contributor_orchestra = "Contributor Orchestra should be less than 500 characters.";
                }
            })
        }
        

        if (data.trackMetadata.valid_from_date && data.trackMetadata.valid_to_date 
            && data.trackMetadata.valid_from_date > data.trackMetadata.valid_to_date) {
                errors.valid_from_date = "Valid from should be less than valid to.";
                errors.valid_to_date = "Valid from should be less than valid to.";
        }


        //Album Validation
        if (data.albumMetadata) {

            if ((!data.albumMetadata.album_title || data.albumMetadata.album_title == "") && (!data.albumMetadata.album_title.length || data.albumMetadata.album_title.length == 0)) {
                errors.album_title = "Album Title should not be empty.";
            }
            if (data.albumMetadata.album_title && data.albumMetadata.album_title.length > textLengths.TEXT) {
                errors.album_title = "Album title should be less than 256 characters.";
            }
            if (data.albumMetadata.album_artist && data.albumMetadata.album_artist.length > textLengths.MEDIUMTEXT) {
                errors.album_artist = "Peformer should be less than 500 characters.";
            }
            if (data.albumMetadata.catalogue_number && data.albumMetadata.catalogue_number.length > 32) {
                errors.catalogue_number = "Catalog # should be less than 33 characters.";
            }
            if (data.albumMetadata.album_notes && data.albumMetadata.album_notes.length > textLengths.LONGTEXT) {
                errors.album_notes = "Album notes should be less than 1000 characters.";
            }
            if (data.albumMetadata.album_release_date && data.albumMetadata.album_release_date.length > textLengths.TEXT) {
                errors.album_release_date = "Album release date should be less than 256 characters.";
            }
            if (data.albumMetadata.cLine && data.albumMetadata.cLine.length > textLengths.TEXT) {
                errors.cLine = "Album Cline should be less than 256 characters.";
            }
            if (data.albumMetadata.upc && data.albumMetadata.upc.length > 32) {
                errors.upc = "Album UPC should be less than 33 characters.";
            }
            if (data.albumMetadata.bbc_album_id && data.albumMetadata.bbc_album_id.length > 32) {
                errors.bbc_album_id = `${process.env.REACT_APP_CLIENT_NAME?process.env.REACT_APP_CLIENT_NAME:"BBC"} album barcode should be less than 33 characters.`;
            }
            if (data.albumMetadata.org_album_admin_notes && data.albumMetadata.org_album_admin_notes.length > textLengths.LONGTEXT) {
                errors.org_album_admin_notes = "Album admin notes should be less than 1000 characters.";
            }

            if (data.albumMetadata.album_discs) {
                if(data.albumMetadata.album_discs > 2147483647)
                errors.album_discs = "Album discs should be less than 2147483647";
            }
    
            if (data.albumMetadata.prod_year) {
                if(data.albumMetadata.prod_year > 3000 || data.albumMetadata.prod_year < 1000)
                errors.prod_year = "Prod. year should be between 1000 and 3000";
            }

            if (data.trackMetadata.position) {
                if(data.trackMetadata.position > 1000 || data.trackMetadata.position < 0)
                errors.position = "Position should be between 0 and 1000";
            }

            if (data.albumMetadata.org_album_adminTags && data.albumMetadata.org_album_adminTags.length > 0) {
                data.albumMetadata.org_album_adminTags.map((org_album_adminTag) => {
                    if (org_album_adminTag && org_album_adminTag.length > 32) {
                        errors.org_album_adminTags = "Album Tags should be less than 33 characters.";
                    }
                })
            }
        }

    }
    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
};

export const validateCustomTrack = (data) => {
    let errors = {};
    

    //Track Data Validation
    if (!data.trackTitle && !data.trackTitle.length) {
        errors.trackTitle = "Title should not be empty.";
    }
    if (data.trackTitle && data.trackTitle.length > textLengths.TEXT) {
        errors.trackTitle = "Title should be less than 256 characters.";
    }
    if (data.isrc && data.isrc.length > 32) {
        errors.isrc = "ISRC should be less than 33 characters.";
    }
    if (data.recordLabel && data.recordLabel.length > textLengths.TEXT) {
        errors.recordLabel = "Record label should be less than 256 characters.";
    }
    if (data.iswc && data.iswc.length > 32) {
        errors.iswc = "ISWC should be less than 33 characters.";
    }
    if (data.bpm && data.bpm.length > textLengths.TEXT) {
        errors.bpm = "BPM should be less than 256 characters.";
    }
    if (data.tempo && data.tempo.length > textLengths.TEXT) {
        errors.tempo = "Tempo should be less than 256 characters.";
    }
    if (data.trackNotes && data.trackNotes.length > textLengths.TEXT) {
        errors.trackNotes = "Track Notes should be less than 256 characters.";
    }
    if (data.prs && data.prs.length > 32) {
        errors.prs = "Datahub PRS should be less than 33 characters.";
    }
    if (data.performer && data.performer.length > 0) {
        data.performer.map((p) => {
            if (p && p.length > textLengths.TEXT) {
                errors.performer = "Performers should be less than 256 characters.";
            }
        })
    }
    if (data.arranger && data.arranger.length > 0) {
        data.arranger.map((a) => {
            if (a && a.length > textLengths.TEXT) {
                errors.arranger = "Arrangers should be less than 256 characters.";
            }
        })
    }
    if (data.publisher && data.publisher.length > 0) {
        data.publisher.map((p) => {
            if (p && p.length > textLengths.TEXT) {
                errors.publisher = "Publishers should be less than 256 characters.";
            }
        })
    }
    if (data.lyricist && data.lyricist.length > 0) {
        data.lyricist.map((l) => {
            if (l && l.length > textLengths.TEXT) {
                errors.lyricist = "Lyricist should be less than 256 characters.";
            }
        })
    }
    if (data.composer && data.composer.length > 0) {
        data.composer.map((c) => {
            if (c && c.length > textLengths.TEXT) {
                errors.composer = "Composers should be less than 256 characters.";
            }
        })
    }
    if (data.translator && data.translator.length > 0) {
        data.translator.map((t) => {
            if (t && t.length > textLengths.TEXT) {
                errors.translator = "Translators should be less than 256 characters.";
            }
        })
    }

    if (data.prodName && data.prodName.length > textLengths.TEXT) {
        errors.prodName = "Album title should be less than 256 characters.";
    }
    
    if (data.catNo && data.catNo.length > 32) {
        errors.catNo = "Catalog # should be less than 33 characters.";
    }
    

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
};

export const validateEditTrackContributors = (data) => {
    let errors = {};
    if (data) {
        const regex = new RegExp('\d{15,15}[\dx]$');

        if (!data.name || !data.name.length) {
            errors.name = "Contributor Name should not be empty.";
        }
        if (!data.role || !data.role.length) {
            errors.role = "Role should not be empty.";
        }
        if (data.name && data.name.length > textLengths.TEXT) {
            errors.name = "Contributor name should be less than 256 characters.";
        }
        if (data.isni && data.name.length > textLengths.TEXT) {
            errors.isni = "Contributor ISNI should be less than 256 characters.";
        }
        if (data.isni && !regex.test(data.isni)) {
            errors.isni = "ISNI should be valid.";
        }
    }
    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}

