import React from 'react'
 import store from "redux/Store";
import { CFOrgStatus, PageTableType } from "../redux/Types";
import { _mlLicensing, _mlLicensingWorkflowPermissionOptions } from "../_module/definition/MlLicensing";
import i18next from "i18next";
import { _PermittedContent } from '_module/utils/PermittedContent';
import { _mlPlaylists } from '_module/definition/MlPlaylists';
import { _playouts } from '_module/definition/playout';
import { _mlLibraries } from '_module/definition/MlLibraries';
import { _manageMlLiRules, _mlLicensingAdmin } from '_module/definition/MlLicensingAdmin';
import { _mlContentAdmin } from '_module/definition/MlContentAdmin';
import { _contentRequest } from '_module/definition/ContentTrackerPermit';
import { _manageMlUsers, _mlAdmin } from '_module/definition/MlAdmin';
import { _smReports } from '_module/definition/SmReports';

const pad = (num) => {
  return ("0" + num).slice(-2);
};

export const secsToTime = (secs) => {
  var minutes = Math.floor(secs / 60);
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(Math.floor(secs % 60))}`;
};

export const titleCase = (str) => {
  var wordsArray = str.toLowerCase().split(/\s+/);
  var upperCased = wordsArray.map(function (word) {
    return word.charAt(0).toUpperCase() + word.substr(1);
  });
  return upperCased.join(" ");
}

export const userDisplay = (userId) => {
  const {
    inbox: { allUsers },
  } = store.getState();
  // return (allUsers.find((v) => +v.userId === +userId) || { name: "" }).name + " " + (allUsers.find((v) => +v.userId === +userId) || { email: "" }).email;
  return (getUserNameWithDetails(allUsers.find((v) => +v.userId === +userId) || { name: " ", }));
}
export const userDisplayImage = (userId) => {
  const {
    inbox: { allUsers },
  } = store.getState();
  return (allUsers.find((v) => +v.userId === +userId) || { name: "Not found" }).imageUrl;
}

export const deepCopyFunction = (inObject) => {
  let outObject, value, key

  if (typeof inObject !== "object" || inObject === null || inObject instanceof Date) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value)
  }

  return outObject
}

export const makeRandomId = (letters = 3, digits = 5) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var numbers       = '0123456789';
  var charactersLength = characters.length;
  var numbersLength = numbers.length;
  for ( var i = 0; i < letters; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  for ( var i = 0; i < digits; i++ ) {
    result += numbers.charAt(Math.floor(Math.random() * numbersLength));
  }
  return result;
//  return shuffleString(result);
}

export const shuffleString = s => {
  let shuffled = s.split('').reduceRight((res,_,__,s) => (res.push(s.splice(0|Math.random()*s.length,1)[0]), res),[]);
  return shuffled.join('');
}

export const getFilteredStatus = (statuses,currentStatus, workFlowPermission = _mlLicensingWorkflowPermissionOptions.default.id, hasRefer = false, hasUnClear = false ) =>{

  let permittedStatuses = [];
  if(workFlowPermission == _mlLicensingWorkflowPermissionOptions.allAdmin.id){
    return statuses
  }
  else if(workFlowPermission == _mlLicensingWorkflowPermissionOptions.musicRightsAdmin.id){
    switch (currentStatus){
      case CFOrgStatus.INPROGRESS:        
        permittedStatuses = [CFOrgStatus.INPROGRESS];   
        if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);     
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )     
      case CFOrgStatus.COMPLETED:
        permittedStatuses = [CFOrgStatus.COMPLETED];
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
      case CFOrgStatus.WITHDRAWN:
        permittedStatuses = [CFOrgStatus.WITHDRAWN];
        if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
      case CFOrgStatus.REVIEWED:
        permittedStatuses = [CFOrgStatus.REVIEWED,CFOrgStatus.WITHDRAWN];
        if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
       case CFOrgStatus.SUBMITTED:
        permittedStatuses = [CFOrgStatus.SUBMITTED];
      if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);
      if(!hasUnClear) permittedStatuses.push(CFOrgStatus.REVIEWED);
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 ) 
      default:
      return statuses
    }

  }
  else{    
    switch (currentStatus){
      case CFOrgStatus.INPROGRESS:        
        permittedStatuses = [CFOrgStatus.INPROGRESS];
        if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);
        if(!hasUnClear) permittedStatuses.push(CFOrgStatus.SUBMITTED);
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
      case CFOrgStatus.SUBMITTED:
        permittedStatuses = [CFOrgStatus.SUBMITTED,CFOrgStatus.WITHDRAWN];
        if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
      case CFOrgStatus.COMPLETED:
        permittedStatuses = [CFOrgStatus.COMPLETED,CFOrgStatus.WITHDRAWN];
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
      case CFOrgStatus.WITHDRAWN:
        permittedStatuses = [CFOrgStatus.WITHDRAWN];
        if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);
        if(!hasUnClear) permittedStatuses.push(CFOrgStatus.SUBMITTED);
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
      case CFOrgStatus.REVIEWED:
        permittedStatuses = [CFOrgStatus.REVIEWED];
        if(!hasRefer && !hasUnClear) permittedStatuses.push(CFOrgStatus.COMPLETED);
       return statuses.filter(item => permittedStatuses.indexOf(item.id) !=-1 )
      default:
      return statuses
    }
  }


}

export const getUserNameWithDetails = (user) =>{
  return (!process.env.REACT_APP_CLIENT_NONBBC || process.env.REACT_APP_CLIENT_NONBBC == false)? (user.name || user.label) + " " +  (user.bbcCompany || "") + " " + (user.bbcDepartment || "") : (user.name || user.label)
}

export const getContributorDetails = (contributor) =>{
  return(contributor.map((item,i, array) =>(
    <>
    <span title={stripedContributorField(item.role)+": "+item.name}><i>{stripedContributorField(item.role)}</i>: <b>{item.name}{i === array.length - 1 ? null : ", "}</b></span>
    </>
  )))
}

export const getChangeLogDetails = (change) =>{
  return(change.map((item, i, array) =>(
    <span>[
    <span title={"Action: "+(item.action?item.action:"")}><i>ACTION</i>: <b>{item.action?item.action:""}</b>, </span>
    <span title={"Date Created: "+(item.dateCreated?item.dateCreated:"")}><i>DATE CREATED</i>: <b>{item.dateCreated?item.dateCreated:""}</b>, </span>
    <span title={"User Name: "+(item.userName? item.userName:"")}><i>USER NAME</i>: <b>{item.userName? item.userName:""}</b></span>]
    
    {i === array.length - 1 ? null : <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>}
    </span>
  )))
}

export const stripedContributorField = (field) => {
  let cField = field.replace("_", " ")
  return cField.toUpperCase();
}

export const hotKeyNavigate = (props,keyName,currentPage) => {
 if(keyName){
   let isPermitted = false;
   switch (keyName){
    case "alt+p":
      isPermitted = isPermissionGranted([_mlPlaylists]);
      if(currentPage != PageTableType.MYPLAYLIST && isPermitted){
        props.history.push('/playlist')
      }      
      break;
    case "alt+s":
      isPermitted = isPermissionGranted([_mlLibraries]);
      if(currentPage != PageTableType.SEARCH_TRACKS && isPermitted){
        // props.history.push('/search/tracks')
        props.history.push({
          pathname: '/search/tracks',
          state: {autofocus:false},
        });
      }      
      break;
    case "alt+c":
      isPermitted = isPermissionGranted([_mlLicensing]);
      if(currentPage != PageTableType.CLEARANCE_FORMS && isPermitted){
        props.history.push('/mllicensing/forms')
      }      
      break;
    case "alt+o":
      isPermitted = isPermissionGranted([_playouts]);
      if(currentPage != PageTableType.PLAYOUT && isPermitted){
        props.history.push('/playout/playout-session')
      }      
      break;
    case "alt+l":
      isPermitted = isPermissionGranted([_mlLicensingAdmin, _manageMlLiRules]);
      if(currentPage != PageTableType.LICENCING_ADMIN_RULES && isPermitted){
        props.history.push('/mllicensingadmin/rules')
      }      
      break;
    case "alt+w":
      isPermitted = isPermissionGranted([_mlContentAdmin]);
      if(currentPage != PageTableType.MLCADMIN_WORKSPACES && isPermitted){
        props.history.push('/ml-admin/workspaces')
      }      
      break;
    case "alt+r":
      isPermitted = isPermissionGranted([_contentRequest]);
      if(currentPage != PageTableType.CONTENT_REQUEST && isPermitted){
        props.history.push('/content-tracker/requests')
      }      
      break;
    case "alt+u":
      isPermitted = isPermissionGranted([_mlAdmin, _manageMlUsers]);
      if(currentPage != PageTableType.ADMIN_USERS && isPermitted){
        props.history.push('/mladmin/users')
      }      
      break;
    case "alt+t":
      isPermitted = isPermissionGranted([_smReports]);
      if(currentPage != PageTableType.REPORTS && isPermitted){
        props.history.push('/reports')
      }      
      break;
   }
 }
}
const isPermissionGranted = (permission) => {
  return _PermittedContent(permission)
    ? true
    : false;
};

export const trimSearchQuery = (query) => {
  try{
    query.q = query.q?(typeof query.q != "string"?query.q.toString().trim():query.q.trim()):query.q;
    query.filters = query.filters.map(d=>{
      d.value = d.value.map(k=>k?(typeof k != "string"?k.toString().trim():k.trim()):k);
      return d;
    })
  }
  catch(e){
    console.error("trimSearchQuery", e);
  }
  return query;
};