import axios from "axios";
import jwtDecode from "jwt-decode";
import { _smSysAdmin } from "_module/definition/SmSysAdmin";
import { _mlLibraries } from "../../_module/definition/MlLibraries";
import { _mlPlaylists } from "../../_module/definition/MlPlaylists";
import { _mlPlayer } from "../../_module/definition/MlPlayer";
import { _mlAdmin } from "../../_module/definition/MlAdmin";

import {
  validateEmail,
  validateLogin,
  validateResetPassword,
  validateSignup,
  validate2fa,
  validatePhoneNumber,
} from "../../util/validations/UserActionsValidation";
import { host_basename } from "../../App";
import store from "redux/Store";
module = "/user";
export const actionTypes = {
  LOADING: "LOADING" + module,
  RESENDING: "RESENDING" + module,
  GETCODE: "GETCODE" + module,
  LOAD: "LOAD" + module,
  EXPORTING: "EXPORTING" + module,
  EXPORTED: "EXPORTED" + module,
  EXPORTED_OK: "EXPORTED_OK" + module,
  ERROR: "ERROR" + module,
  SET_USER: "SET_USER",
  SET_2FA: "SET_2FA",
  SET_USER_IMAGE: "SET_USER_IMAGE",
  SET_UNAUTHENTICATED: "SET_UNAUTHENTICATED",
  SET_USER_PERMISSION: "SET_USER_PERMISSION",
  TOGGLE_NAV: "TOGGLE_NAV" + module,
  TOGGLE_PV: "TOGGLE_PV" + module,
  CLEAR_ERRORS: "CLEAR_ERRORS" + module,
  REMOVE_USER_IMAGE: "REMOVE_USER_IMAGE" + module,
};

export const toggleNav = (t) => (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_NAV,
    payload: t,
  });
};

export const togglePV = (t) => (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_PV,
    payload: t,
  });
};

export const loginSSOUser = (token, history) => (dispatch) => {
  axios
    .post("/loginSSO", { token: token })
    .then((res) => {
      cacheLoggedInUser(res.data.user, res.data.token);
      dispatch({
        type: actionTypes.SET_USER,
        payload: res.data.user,
      });
      history.replace("/pl-promo");
    })
    .catch((err) => {
      forgetLoggedInUser();
      history.replace("/sigin");
    });
};

export const savePhone = (userData) => (dispatch) => {
  let errors = validatePhoneNumber(userData);

  if (errors) {
    dispatch({
      type: actionTypes.ERROR,
      payload: errors,
    });
    return;
  }

  dispatch({ type: actionTypes.GETCODE });
  axios
    .post("/registerPhone", userData)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_2FA,
        payload: {
          email: res.data.email,
          phone: res.data.phone,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: actionTypes.ERROR,
        payload: err.response?.data || { e: err.message },
      });
    });
};

export const twoFaResendCode = (userData) => (dispatch) => {
  dispatch({ type: actionTypes.RESENDING });
  axios
    .post("/2faResend", userData)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_2FA,
        payload: {
          email: res.data.email,
          phone: res.data.phone,
        },
      });
    })
    .catch((err) => {
      forgetLoggedInUser();
      dispatch({
        type: actionTypes.ERROR,
        payload: err.response?.data || { e: err.message },
      });
    });
};

export const twoFaUser = (userData, history) => (dispatch) => {
  let errors = validate2fa(userData);

  if (errors) {
    dispatch({
      type: actionTypes.ERROR,
      payload: errors,
    });
    return;
  }

  dispatch({ type: actionTypes.LOADING });
  axios
    .post("/2fa", userData)
    .then((res) => {
      cacheLoggedInUser(res.data.user, res.data.token);
      dispatch({
        type: actionTypes.SET_USER,
        payload: res.data.user,
      });
      history.replace("/pl-promo");
    })
    .catch((err) => {
      forgetLoggedInUser();
      dispatch({
        type: actionTypes.ERROR,
        payload: err.response?.data || { e: err.message },
      });
    });
};

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: actionTypes.LOADING });
  let errors = validateLogin(userData);
  if (errors) {
    dispatch({
      type: actionTypes.ERROR,
      payload: errors,
    });
    return;
  }
  axios
    .post("/login", userData)
    .then((res) => {
      if (res.data.tfa) {
        dispatch({
          type: actionTypes.SET_2FA,
          payload: {
            email: res.data.email,
            phone: res.data.phone,
          },
        });
        history.replace("/2fa");
      } else {
        cacheLoggedInUser(res.data.user, res.data.token);
        dispatch({
          type: actionTypes.SET_USER,
          payload: res.data.user,
        });
        history.replace("/pl-promo");
      }
    })
    .catch((err) => {
      forgetLoggedInUser();
      dispatch({
        type: actionTypes.ERROR,
        payload: err.response?.data || { e: err.message },
      });
    });
};

export const setUserImage = (imageUrl) => (dispatch) => {
  const user = JSON.parse(localStorage.UserLoggedIn);
  user.credentials.orgs[0].imageUrl = imageUrl;
  localStorage.setItem("UserLoggedIn", JSON.stringify(user));
  dispatch({
    type: actionTypes.SET_USER_IMAGE,
    payload: imageUrl,
  });
};

export const removeUserImage = (item) => (dispatch) => {
  const user = JSON.parse(localStorage.UserLoggedIn);
  user.credentials.orgs[0].imageUrl = null;
  localStorage.setItem("UserLoggedIn", JSON.stringify(user));
  dispatch({
    type: actionTypes.REMOVE_USER_IMAGE,
    payload: null,
  });
};

export const setExportLoading = (loading, message = null) => (dispatch) => {
  if (!loading) {
    dispatch({
      type: actionTypes.EXPORTED,
      payload: message?message:"Exporting Success",
    });
    setTimeout(()=>{
      dispatch({
        type: actionTypes.EXPORTED_OK,
      });
    }, 2000);
  } else {
    dispatch({
      type: actionTypes.EXPORTING,
      payload: "Exporting Inprogress",
    });
  }
};

export const signupInfo = (handle, done) => (dispatch) => {
  axios
    .post("/signupurl", handle)
    .then((res) => {
      done(res.data, null);
    })
    .catch((err) => {
      done(null, err.response?.data || { e: err.message });
    });
};

export const signupUser = (newUserData, bbcSSO, signedup, history) => (
  dispatch
) => {
  let errors = validateSignup(newUserData, bbcSSO, signedup);
  if (errors) {
    dispatch({
      type: actionTypes.ERROR,
      payload: errors,
    });
    return;
  }

  dispatch({ type: actionTypes.LOADING });
  axios
    .post("/signup", newUserData)
    .then((res) => {
      if (bbcSSO) {
        forgetLoggedInUser();
        history.replace("/signin?sso=1");
      } else {
        cacheLoggedInUser(res.data.user, res.data.token);
        dispatch({
          type: actionTypes.SET_USER,
          payload: res.data.user,
        });
        history.replace("/pl-promo");
      }
    })
    .catch((err) => {
      forgetLoggedInUser();
      dispatch({
        type: actionTypes.ERROR,
        payload: err.response?.data || { e: err.message },
      });
    });
};

export const logoutUser = () => (dispatch) => {
  const { user } = store.getState();
  if (user && user.email) axios.post("/logout", { email: user.email });

  forgetLoggedInUser();
  dispatch({ type: actionTypes.SET_UNAUTHENTICATED });
};

export const loadLoggedinUser = () => (dispatch) => {
  let pageUrls = [
    `${host_basename}/signup`,
    `${host_basename}/signin`,
    `${host_basename}/contact`,
    `${host_basename}/resetpassword`,
    `${host_basename}/bbcsso`,
  ];

  if (pageUrls.find((v) => window.location.pathname.startsWith(v))) {
    logoutUser();
    return;
  }

  const token = localStorage.FBIdToken;
  if (token) {
    const user = JSON.parse(localStorage.UserLoggedIn);
    dispatch({
      type: actionTypes.SET_USER,
      payload: user,
    });
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["email"] = user.email;
    //}
  } else {
    logoutUser();
    if (
      window.location.pathname?.toLocaleLowerCase() !==
      `${host_basename}/signin`
    ) {
      window.location.href = `${host_basename}/signin`;
    }
  }
};

export const resetPw = (userData, history, done = null) => (dispatch) => {
  let errors = validateEmail(userData.email);
  if (errors) {
    dispatch({
      type: actionTypes.ERROR,
      payload: { email: errors },
    });
    return;
  }

  dispatch({ type: actionTypes.LOADING });
  axios
    .post("/resetpassword", userData)
    .then((res) => {
      dispatch({
        type: actionTypes.LOAD,
        payload: res.data.e,
      });
      // history.replace("/");
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: err.response?.data || { e: err.message },
      });
      if (done) done(null, err);
    });
};

export const handleResetPw = (userData, done = null) => (dispatch) => {
  let errors = validateResetPassword(userData);
  if (errors) {
    dispatch({
      type: actionTypes.ERROR,
      payload: errors,
    });
    return;
  }

  dispatch({ type: actionTypes.LOADING });
  axios
    .post("/handleResetPassword", userData)
    .then((res) => {
      dispatch({
        type: actionTypes.LOAD,
        payload: res.data.e,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: err.response?.data || { e: err.message },
      });
      if (done) done(null, err);
    });
};

const cacheLoggedInUser = (user, token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  localStorage.setItem("UserLoggedIn", JSON.stringify(user));
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};

const forgetLoggedInUser = () => {
  localStorage.removeItem("FBIdToken");
  localStorage.removeItem("UserLoggedIn");
  delete axios.defaults.headers.common["Authorization"];
};

export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};
