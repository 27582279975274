// export const helpType = {
//   ALL: "general",

//   TRACKS_SEARCH: "tracksSearch",
//   ALBUM_SEARCH: "albumSearch",
//   SAVED_SEARCH: "savedSearch",

//   ADVANCED_SEARCH: "advancedSearch",

//   MY_PLAYLIST: "myPlayList",
//   PROMO_PLAYLIST: "promoPlayList",

//   CLEARANCE_FORMS: "clearanceForms",
//   CLEARANCE_TRACKS: "clearanceTracks",
// };


export const helpType = {
  ALL: "general",

  //search
  TRACKS_SEARCH: "tracksSearch",
  ALBUM_SEARCH: "albumSearch",
  SAVED_SEARCH: "savedSearch",

  // ADVANCED_SEARCH: "advancedSearch",

  //playlist
  MY_PLAYLIST: "myPlayList",
  PROMO_PLAYLIST: "promoPlayList",
  PLAYLIST_INBOX: "playlistInbox",
  PLAYLIST_OUTBOX: "playlistOutbox",

  // clearance
  CLEARANCE_FORMS: "clearanceForms",
  CLEARANCE_TRACKS: "clearanceTracks",

  // licensing
  LICENSING_RULES: "licensingRules",
  LICENSING_STATUSES: "licensingStatuses",
  LICENSING_PARAMETERS: "licensingParameters",

  // content admin
  CADMIN_WORKSPACES: "contentAdminWorkspaces",
  CADMIN_LIBRARIES: "contentAdminLibraries",
  CADMIN_CTAGS: "contentAdminCTAGS",
  CADMIN_CTAG_RULES: "contentAdminCTAGRules",
  CADMIN_PPL_LABELS: "contentAdminPPLLabels",
  CADMIN_PRI_APPROVAL: "contentAdminPriorApproval",

  // admin
  ADMIN_USERS: "adminUsers",
  ADMIN_GROUPS: "adminGroups",
  ADMIN_ROLES: "adminRoles",
  ADMIN_SETTINGS: "adminSettings",
  ADMIN_TRACK_RANKING: "adminTrackRanking",
  ADMIN_ALBUM_RANKING: "adminAlbumRanking",
  ADMIN_ACTIVITY_LIMIT: "adminActivityLimitations",
  ADMIN_PLAYLIST_CAT: "adminPlaylistCategory",

  // sys admin
  SYS_ADMIN_ORG : "smSysAdminOrganizations",
  SYS_ADMIN_USERS : "smSysAdminUsers",

  // playout
  PLAYOUTS : "playouts",

  // uploads
  UPLOAD_SESSION : "uploadSession",
  UPLOAD_TRACKS : "uploadTracks",
  UPLOAD_ALBUMS : "uploadAlbums",

  // sm admin
  SADMIN_WORKSPACES : "smAdminWorkspaces",
  SADMIN_LIBRARIES : "smAdminLibraries",

  // activity log
  ACTIVITY_LOG : "activityLog",

  // reports
  REPORTS : "reports",
};


export const helpTypes = [
  { id: helpType.ALL, label: "All" },

  { id: helpType.TRACKS_SEARCH, label: "Tracks Search" },
  { id: helpType.ALBUM_SEARCH, label: "Album Search" },
  { id: helpType.SAVED_SEARCH, label: "Saved Search" },

  // { id: helpType.ADVANCED_SEARCH, label: "Advanced Search" },

  { id: helpType.MY_PLAYLIST, label: "My Playlist" },
  { id: helpType.PROMO_PLAYLIST, label: "Promo Playlist" },

  { id: helpType.CLEARANCE_FORMS, label: "Clearance - Forms" },
  { id: helpType.CLEARANCE_TRACKS, label: "Clearance - Tracks" },

  { id: helpType.LICENSING_RULES, label: "Licensing - Rules" },
  { id: helpType.LICENSING_PARAMETERS, label: "Licensing - Parameters" },
  { id: helpType.LICENSING_STATUSES, label: "Licensing - Statuses" },


  { id: helpType.CADMIN_WORKSPACES, label: "Content Admin - Workspaces" },
  { id: helpType.CADMIN_LIBRARIES, label: "Content Admin - Libraries" },
  { id: helpType.CADMIN_CTAGS, label: "Content Admin - CTAGS" },
  { id: helpType.CADMIN_CTAG_RULES, label: "Content Admin - CTAG Rules" },
  { id: helpType.CADMIN_PPL_LABELS, label: "Content Admin - PPL Labels" },
  { id: helpType.CADMIN_PRI_APPROVAL, label: "Content Admin - Prior Approval" },


  { id: helpType.ADMIN_USERS, label: "Admin - Users" },
  { id: helpType.ADMIN_GROUPS, label: "Admin - Groups" },
  { id: helpType.ADMIN_ROLES, label: "Admin - Roles" },
  { id: helpType.ADMIN_SETTINGS, label: "Admin - Settings" },
  { id: helpType.ADMIN_TRACK_RANKING, label: "Admin - Track Ranking" },
  { id: helpType.ADMIN_ALBUM_RANKING, label: "Admin - Album Ranking" },
  { id: helpType.ADMIN_ACTIVITY_LIMIT, label: "Admin - Activity Limitations Settings" },
  { id: helpType.ADMIN_PLAYLIST_CAT, label: "Admin - Playlist Category" },
];

export const helpViewType = {
  SINGLE: 1,
  MULTIPLE: 2,
  TOOLTIP: 3,
};

export const helpContentType = {
  TITLE: 1,
  PARAGRAPH: 2,
  IMAGE: 3,
  VIDEO: 4,
  EMBEDDED: 5,
  PRIVATE_VIDEO: 6
};

export const defaultDementions = {
  WIDTH : 350,
  HEIGHT : 200
}

export const helpContentTypes = [
  { id: helpContentType.TITLE, label: "Title" },
  { id: helpContentType.PARAGRAPH, label: "Paragraph" },
  { id: helpContentType.IMAGE, label: "Image" },
  { id: helpContentType.VIDEO, label: "Public Video" },
  { id: helpContentType.PRIVATE_VIDEO, label: "Private Video" },
  { id: helpContentType.EMBEDDED, label: "Embedded" },
]

export const helpContentTitleType = {
  HUGE : 1,
  LARGE: 2,
  NORMAL: 3,
  SMALL: 4,
};

export const helpTitleTypes = [
  { id: helpContentTitleType.SMALL, label: "Small" },
  { id: helpContentTitleType.NORMAL, label: "Normal" },
  { id: helpContentTitleType.LARGE, label: "Large" },
  { id: helpContentTitleType.HUGE, label: "Huge" },

];

export const helpContentAlignType = {
  LEFT: 1,
  RIGHT: 2,
  CENTER: 3,
  STRECH: 4
};

export const helpContentAlignTypes = [
  { id: helpContentAlignType.LEFT, label: "Left" },
  { id: helpContentAlignType.RIGHT, label: "Right" },
  { id: helpContentAlignType.CENTER, label: "Center" },
  { id: helpContentAlignType.STRECH, label: "Strech" },
]

export const helpContentElement = {};

// ~~~~ NOTE : helpType value, helpModules "module" and help-content-all.json "module" must be same ~~~~
export const helpModules = {
  // Header
  general: {
    id: "general",
    module: helpType.ALL,
    label: "General Help",
    type: helpViewType.MULTIPLE,
  },

  //Search
  // Track
  TSPWH: {
    id: "TSPWH",
    module: helpType.TRACKS_SEARCH,
    label: "Track Search Preview Header",
    type: helpViewType.SINGLE,
  },

  TSAB: {
    id: "TSAB",
    module: helpType.TRACKS_SEARCH,
    label: "Track Search App Bar",
    type: helpViewType.MULTIPLE,
  },

  TSRCM: {
    id: "TSRCM",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu",
    type: helpViewType.SINGLE,
  },

  // track search context menu buttons
  TSRCMEDT: {
    id: "TSRCMEDT",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Edit Button",
    type: helpViewType.TOOLTIP,
  },

  TSRCMDelete: {
    id: "TSRCMDelete",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Delete Button",
    type: helpViewType.TOOLTIP,
  },
  TSRCMExplicitContent: {
    id: "TSRCMExplicitContent",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Explicit Content Button",
    type: helpViewType.TOOLTIP,
  },
  TSRCMRestrict: {
    id: "TSRCMRestrict",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Restrict Content Button",
    type: helpViewType.TOOLTIP,
  },
  TSRCMUndoRestrict: {
    id: "TSRCMUndoRestrict",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Undo Restrict Content Button",
    type: helpViewType.TOOLTIP,
  },
  TSRCMArchive: {
    id: "TSRCMArchive",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Archive Button",
    type: helpViewType.TOOLTIP,
  },
  TSRCMRestore: {
    id: "TSRCMRestore",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Restore Button",
    type: helpViewType.TOOLTIP,
  },
  
  TSRCMContentMessage: {
    id: "TSRCMContentMessage",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Context Menu - Content Message Button",
    type: helpViewType.TOOLTIP,
  },

    // track search action menu buttons
  TSRAMContentMessage: {
    id: "TSRAMContentMessage",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Action Menu - Content Message Button",
    type: helpViewType.TOOLTIP,
  },

  TSRAM_PubToPlayout: {
    id: "TSRAM_PubToPlayout",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Action Menu - Publish to Playout Button",
    type: helpViewType.TOOLTIP,
  },

  TSRAM_UndoExplicitContent: {
    id: "TSRAM_UndoExplicitContent",
    module: helpType.TRACKS_SEARCH,
    label: "Track Result Action Menu - Undo Explicit Content Button",
    type: helpViewType.TOOLTIP,
  },


  // Album
  ASPWH: {
    id: "ASPWH",
    module: helpType.ALBUM_SEARCH,
    label: "Album Search Preview Header",
    type: helpViewType.SINGLE,
  },

  ASAB: {
    id: "ASAB",
    module: helpType.ALBUM_SEARCH,
    label: "Album Search App Bar",
    type: helpViewType.MULTIPLE,
  },

  // album search context menu buttons
  ASRCM: {
    id: "ASRCM",
    module: helpType.ALBUM_SEARCH,
    label: "Album Result Context Menu",
    type: helpViewType.SINGLE,
  },

  ASRCM_Archive: {
    id: "ASRCM_Archive",
    module: helpType.ALBUM_SEARCH,
    label: "Track Result Context Menu - Archive Button",
    type: helpViewType.TOOLTIP,
  },
  ASRCM_Edit: {
    id: "ASRCM_Edit",
    module: helpType.ALBUM_SEARCH,
    label: "Track Result Context Menu - Edit Button",
    type: helpViewType.TOOLTIP,
  },

  // album track
  ATSPWH: {
    id: "ATSPWH",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Preview Header",
    type: helpViewType.SINGLE,
  },

  ATSAB: {
    id: "ATSAB",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Search App Bar",
    type: helpViewType.MULTIPLE,
  },

  ATRCM_Edit: {
    id: "ATRCM_Edit",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Result Context Menu - Edit Button",
    type: helpViewType.TOOLTIP,
  },

  ATRCM_Delete: {
    id: "ATRCM_Delete",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Result Context Menu - Delete Button",
    type: helpViewType.TOOLTIP,
  },
  ATRCM_ExplicitContent: {
    id: "ATRCM_ExplicitContent",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Result Context Menu - Explicit Content Button",
    type: helpViewType.TOOLTIP,
  },

  ATRCM_RstrictContent: {
    id: "ATRCM_RstrictContent",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Result Context Menu - Rstrict Content Button",
    type: helpViewType.TOOLTIP,
  },

  ATRCM_UndoRstrictContent: {
    id: "ATRCM_UndoRstrictContent",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Result Context Menu - Undo Rstrict Content Button",
    type: helpViewType.TOOLTIP,
  },
  ATRCM_Archive: {
    id: "ATRCM_Archive",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Result Context Menu - Archive Button",
    type: helpViewType.TOOLTIP,
  },
  ATRCM_Restore: {
    id: "ATRCM_Restore",
    module: helpType.ALBUM_SEARCH,
    label: "Album Track Result Context Menu - Restore Button",
    type: helpViewType.TOOLTIP,
  },


  // saved search preview header
    SSPWH: {
      id: "SSPWH",
      module: helpType.SAVED_SEARCH,
      label: "Saved Search Preview Header",
      type: helpViewType.SINGLE,
    },

    SSAP: {
      id: "SSAP",
      module: helpType.SAVED_SEARCH,
      label: "Saved Search App Bar",
      type: helpViewType.MULTIPLE,
    },


    // my playlist Preview Header
    MPLPWH: {
      id: "MPLPWH",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Preview Header",
      type: helpViewType.SINGLE,
    },

    MPLAB: {
      id: "MPLAB",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist App Bar",
      type: helpViewType.MULTIPLE,
    },

    MPLCM: {
      id: "MPLCM",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu",
      type: helpViewType.SINGLE,
    },

    MPLCM_Edit: {
      id: "MPLCM_Edit",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Edit Button",
      type: helpViewType.TOOLTIP,
    },

    MPLCM_Share: {
      id: "MPLCM_Share",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Share Button",
      type: helpViewType.TOOLTIP,
    },

    MPLCM_Archive: {
      id: "MPLCM_Archive",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Archive Button",
      type: helpViewType.TOOLTIP,
    },
    MPLCM_Restore: {
      id: "MPLCM_Restore",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Restore Button",
      type: helpViewType.TOOLTIP,
    },
    MPLCM_Promote: {
      id: "MPLCM_Promote",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Promote Button",
      type: helpViewType.TOOLTIP,
    },
    MPLCM_Demote: {
      id: "MPLCM_Demote",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Demote Button",
      type: helpViewType.TOOLTIP,
    },

    MPLCM_DowonloadPlaylist: {
      id: "MPLCM_DowonloadPlaylist",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Dowonload Playlist Button",
      type: helpViewType.TOOLTIP,
    },

    // playlist item
    MPLTPWH: {
      id: "MPLTPWH",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Track Preview Header",
      type: helpViewType.SINGLE,
    },

    MPLTAB: {
      id: "MPLTAB",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Track App Bar",
      type: helpViewType.MULTIPLE,
    },

    MPLCM_RemoveFromPlaylist: {
      id: "MPLCM_RemoveFromPlaylist",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Context Menu - Remove From Playlist Button",
      type: helpViewType.TOOLTIP,
    },

    MPLAM_RemoveFromPlaylist: {
      id: "MPLAM_RemoveFromPlaylist",
      module: helpType.MY_PLAYLIST,
      label: "My Playlist Action Menu - Remove From Playlist Button",
      type: helpViewType.TOOLTIP,
    },

    // promo playlist
    PPLPWH: {
      id: "PPLPWH",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Preview Header",
      type: helpViewType.SINGLE,
    },

    PPLAB: {
      id: "PPLAB",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist App Bar",
      type: helpViewType.MULTIPLE,
    },

    PPLCM_Edit: {
      id: "PPLCM_Edit",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Context Action Menu - Edit Button",
      type: helpViewType.TOOLTIP,
    },

    PPLCM_Share: {
      id: "PPLCM_Share",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Context Action Menu - Share Button",
      type: helpViewType.TOOLTIP,
    },
    PPLCM_Archive: {
      id: "PPLCM_Archive",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Context Action Menu - Archive Button",
      type: helpViewType.TOOLTIP,
    },
    PPLCM_Restore: {
      id: "PPLCM_Restore",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Context Action Menu - Restore Button",
      type: helpViewType.TOOLTIP,
    },
    PPLCM_Promote: {
      id: "PPLCM_Promote",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Context Action Menu - Promote Button",
      type: helpViewType.TOOLTIP,
    },
    PPLCM_Demote: {
      id: "PPLCM_Demote",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Context Action Menu - Demote Button",
      type: helpViewType.TOOLTIP,
    },
    PPLCM_DownloadPlaylist: {
      id: "PPLCM_DownloadPlaylist",
      module: helpType.PROMO_PLAYLIST,
      label: "Promo Playlist Context Action Menu - Download Playlist Button",
      type: helpViewType.TOOLTIP,
    },

    // Clearance Forms Preview Header
    CFPWH: {
      id: "CFPWH",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Preview Header",
      type: helpViewType.SINGLE,
    },

    CFAB: {
      id: "CFAB",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms App Bar",
      type: helpViewType.MULTIPLE,
    },

    CFAM_Archive: {
      id: "CFAM_Archive",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Action Menu - Archive Button",
      type: helpViewType.TOOLTIP,
    },
    CFAM_Restore: {
      id: "CFAM_Restore",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Action Menu - Restore Button",
      type: helpViewType.TOOLTIP,
    },
    CFAM_AllocateToSelf: {
      id: "CFAM_AllocateToSelf",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Action Menu - Allocate To Self Button",
      type: helpViewType.TOOLTIP,
    },
    CFAM_AllocateToOther: {
      id: "CFAM_AllocateToOther",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Action Menu - Allocate To Other",
      type: helpViewType.TOOLTIP,
    },
    CFAM_FormWorkflowStatus: {
      id: "CFAM_FormWorkflowStatus",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Action Menu - Form workflow status",
      type: helpViewType.TOOLTIP,
    },

    CFCM_Archive: {
      id: "CFCM_Archive",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Result Context Menu - Archive Button",
      type: helpViewType.TOOLTIP,
    },
    CFCM_Restore: {
      id: "CFCM_Restore",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Result Context Menu - Restore Button",
      type: helpViewType.TOOLTIP,
    },
    CFCM_AllocateToSelf: {
      id: "CFCM_AllocateToSelf",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Result Context Menu - Allocate To Self Button",
      type: helpViewType.TOOLTIP,
    },
    CFCM_AllocateToOther: {
      id: "CFCM_AllocateToOther",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Result Context Menu - Allocate To Other",
      type: helpViewType.TOOLTIP,
    },
    CFCM_FormWorkflowStatus: {
      id: "CFCM_FormWorkflowStatus",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Forms Result Context Menu - Form workflow status",
      type: helpViewType.TOOLTIP,
    },


    // clearance form
    CFormPWH: {
      id: "CFormPWH",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form Preview Header",
      type: helpViewType.SINGLE,
    },

    CFormAB: {
      id: "CFormAB",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form App Bar",
      type: helpViewType.MULTIPLE,
    },

    CFormAM_Remove: {
      id: "CFormAM_Remove",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form Action Menu - Remove",
      type: helpViewType.TOOLTIP,
    },

    CFormAM_AdminOverride: {
      id: "CFormAM_AdminOverride",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form Action Menu - Admin override to",
      type: helpViewType.TOOLTIP,
    },

    CFormAM_AllocateToSelf: {
      id: "CFormAM_AllocateToSelf",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form Action Menu - Allocate to Self",
      type: helpViewType.TOOLTIP,
    },

    CFormAM_AllocateToOther: {
      id: "CFormAM_AllocateToOther",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form Action Menu - Allocate to other",
      type: helpViewType.TOOLTIP,
    },

    CFormAM_WorkflowStatus: {
      id: "CFormAM_WorkflowStatus",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form Action Menu - Workflow Status",
      type: helpViewType.TOOLTIP,
    },

    CFormCM_Remove: {
      id: "CFormCM_Remove",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Form Context Menu - Remove",
      type: helpViewType.TOOLTIP,
    },

    CF_Wizard: {
      id: "CF_Wizard",
      module: helpType.CLEARANCE_FORMS,
      label: "Clearance Wizard",
      type: helpViewType.SINGLE,
    },

    // Clearance track Preview Header
    CTPWH: {
      id: "CTPWH",
      module: helpType.CLEARANCE_TRACKS,
      label: "Clearance Track Preview Header",
      type: helpViewType.SINGLE,
    },

    CTAB: {
      id: "CTAB",
      module: helpType.CLEARANCE_TRACKS,
      label: "Clearance Track App Bar",
      type: helpViewType.MULTIPLE,
    },

    // licensing admin rules preview header
    LCN_RLS_PWH: {
      id: "LCN_RLS_PWH",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Preview Header",
      type: helpViewType.SINGLE,
    },
    
    // licensing admin rules app bar
    LCN_RLS_APPBR: {
      id: "LCN_RLS_APPBR",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules App Bar",
      type: helpViewType.MULTIPLE,
    },

    // licensing admin rules action menu archive
    LCN_RLS_ACTMN_ARC: {
      id: "LCN_RLS_ACTMN_ARC",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Action Menu Archive",
      type: helpViewType.TOOLTIP,
    },

    // licensing admin rules action menu restore
    LCN_RLS_ACTMN_RST: {
      id: "LCN_RLS_ACTMN_RST",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Action Menu Restore",
      type: helpViewType.TOOLTIP,
    },

    // licensing admin rules context actions edit
    LCN_RLS_CNTXACT_EDT: {
      id: "LCN_RLS_CNTXACT_EDT",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Context Actions Edit",
      type: helpViewType.TOOLTIP,
    },
    // licensing admin rules context actions edit
    LCN_RLS_CNTXACT_CPY: {
      id: "LCN_RLS_CNTXACT_CPY",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Context Actions Copy",
      type: helpViewType.TOOLTIP,
    },
    // licensing admin rules context actions edit
    LCN_RLS_CNTXACT_ARC: {
      id: "LCN_RLS_CNTXACT_ARC",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Context Actions Archive",
      type: helpViewType.TOOLTIP,
    },
    // licensing admin rules context actions edit
    LCN_RLS_CNTXACT_RST: {
      id: "LCN_RLS_CNTXACT_RST",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Context Actions Restore",
      type: helpViewType.TOOLTIP,
    },

    // licensing admin rules builder
    LCN_RLS_builder: {
      id: "LCN_RLS_builder",
      module: helpType.LICENSING_RULES,
      label: "Licensing Rules Add/Edit",
      type: helpViewType.MULTIPLE,
    },


    // licensing admin parameters preview header
    LCN_PRM_PRH: {
      id: "LCN_PRM_PRH",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Preview Header",
      type: helpViewType.SINGLE,
    },
    // licensing admin parameters app bar
    LCN_PRM_APBR: {
      id: "LCN_PRM_APBR",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters App Bar",
      type: helpViewType.MULTIPLE,
    },

    // licensing admin parameters builder
    LCN_PRM_builder: {
      id: "LCN_PRM_builder",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Add/Edit",
      type: helpViewType.MULTIPLE,
    },

    // licensing admin parameters action menu archive
    LCN_PRM_ACTMN_ARC: {
      id: "LCN_PRM_ACTMN_ARC",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Action Menu Archive",
      type: helpViewType.TOOLTIP,
    },
    // licensing admin parameters action menu restore
    LCN_PRM_ACTMN_RST: {
      id: "LCN_PRM_ACTMN_RST",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Action Menu Restore",
      type: helpViewType.TOOLTIP,
    },
    // licensing admin parameters context menu edit
    LCN_PRM_CNTXM_EDT: {
      id: "LCN_PRM_CNTXM_EDT",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Context Menu Edit",
      type: helpViewType.TOOLTIP,
    },
    // licensing admin parameters context menu copy
    LCN_PRM_CNTXM_CPY: {
      id: "LCN_PRM_CNTXM_CPY",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Context Menu Copy",
      type: helpViewType.TOOLTIP,
    },
    // licensing admin parameters context menu archive
    LCN_PRM_CNTXM_ARC: {
      id: "LCN_PRM_CNTXM_ARC",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Context Menu Archive",
      type: helpViewType.TOOLTIP,
    },
    LCN_PRM_CNTXM_RST: {
      id: "LCN_PRM_CNTXM_RST",
      module: helpType.LICENSING_PARAMETERS,
      label: "Licensing Parameters Context Menu Restore",
      type: helpViewType.TOOLTIP,
    },

    //licensing statuses
    LCN_STS_PWH: {
      id: "LCN_STS_PWH",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Preview Header",
      type: helpViewType.SINGLE,
    },

    LCN_STS_APPBAR: {
      id: "LCN_STS_APPBAR",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses App Bar",
      type: helpViewType.MULTIPLE,
    },

    // licensing admin rules builder
    LCN_STS_builder: {
      id: "LCN_STS_builder",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Add/Edit",
      type: helpViewType.MULTIPLE,
    },

    LCN_STS_AC_EDIT: {
      id: "LCN_STS_AC_EDIT",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Context  Menu - Edit Button",
      type: helpViewType.TOOLTIP,
    },

    LCN_STS_AC_COPY: {
      id: "LCN_STS_AC_COPY",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Context  Menu - Copy Button",
      type: helpViewType.TOOLTIP,
    },

    LCN_STS_AC_ARC: {
      id: "LCN_STS_AC_ARC",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Context Menu - Archive Button",
      type: helpViewType.TOOLTIP,
    },
    LCN_STS_AC_RST: {
      id: "LCN_STS_AC_RST",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Context Menu - Restore Button",
      type: helpViewType.TOOLTIP,
    },

    LCN_STS_AM_Archive: {
      id: "LCN_STS_AM_Archive",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Action Menu - Archive Button",
      type: helpViewType.TOOLTIP,
    },
    LCN_STS_AM_Restore: {
      id: "LCN_STS_AM_Restore",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Action Menu - Restore Button",
      type: helpViewType.TOOLTIP,
    },

    LCN_STS_AM_Export: {
      id: "LCN_STS_AM_Export",
      module: helpType.LICENSING_STATUSES,
      label: "Licensing Statuses Action Menu - Export Button",
      type: helpViewType.TOOLTIP,
    },

    // content admin - workspaces
    CAWPWH: {
      id: "CAWPWH",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspaces Preview Header",
      type: helpViewType.SINGLE,
    },

    CAWAB: {
      id: "CAWAB",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspaces App Bar",
      type: helpViewType.MULTIPLE,
    },
    CAWAM_Archive: {
      id: "CAWAM_Archive",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspaces Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    CAWAM_Restrcict: {
      id: "CAWAM_Restrcict",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspaces Action Menu - Restrcict",
      type: helpViewType.TOOLTIP,
    },

    CAWCM_SetLive: {
      id: "CAWCM_SetLive",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspaces Context Menu - Set Live",
      type: helpViewType.TOOLTIP,
    },

    CAWCM_Resync: {
      id: "CAWCM_Resync",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspaces Context Menu - Resync",
      type: helpViewType.TOOLTIP,
    },

     // content admin - Libraries
     CALPWH: {
      id: "CALPWH",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Preview Header",
      type: helpViewType.SINGLE,
    },

    CALAB: {
      id: "CALAB",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries App Bar",
      type: helpViewType.MULTIPLE,
    },

    CALAM_Archive: {
      id: "CALAM_Archive",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    CALAM_Restrcict: {
      id: "CALAM_Restrcict",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Action Menu - Restrcict",
      type: helpViewType.TOOLTIP,
    },

    CALCM_SetLive: {
      id: "CALCM_SetLive",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Context Menu - Set Live",
      type: helpViewType.TOOLTIP,
    },

    CAWLM_Resync: {
      id: "CAWLM_Resync",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Context Menu - Resync",
      type: helpViewType.TOOLTIP,
    },


    // content admin workspace tracks
    CAWTPWH: {
      id: "CAWTPWH",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspace Tracks Preview Header",
      type: helpViewType.SINGLE,
    },

    CAWTAB: {
      id: "CAWTAB",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspace Tracks App Bar",
      type: helpViewType.MULTIPLE,
    },

    CAWTAM_Restrict: {
      id: "CAWTAM_Restrict",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspace Tracks Action Menu - Restrict",
      type: helpViewType.TOOLTIP,
    },
    CAWTAM_Archive: {
      id: "CAWTAM_Archive",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspace Tracks Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },

    CAWTCM_Edit: {
      id: "CAWTCM_Edit",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspace Tracks Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    CAWTCM_RemoveTrack: {
      id: "CAWTCM_RemoveTrack",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspace Tracks Context Menu - Remove Track",
      type: helpViewType.TOOLTIP,
    },
    CAWTCM_RemoveRestrict: {
      id: "CAWTCM_RemoveRestrict",
      module: helpType.CADMIN_WORKSPACES,
      label: "Content Admin Workspace Tracks Context Menu - Remove Restrict",
      type: helpViewType.TOOLTIP,
    },

    // content admin Libraries tracks
    CALTPWH: {
      id: "CALTPWH",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Tracks Preview Header",
      type: helpViewType.SINGLE,
    },

    CALTAB: {
      id: "CALTAB",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Tracks App Bar",
      type: helpViewType.MULTIPLE,
    },

    CALTAM_Restrict: {
      id: "CALTAM_Restrict",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Tracks Action Menu - Restrict",
      type: helpViewType.TOOLTIP,
    },
    CALTAM_Archive: {
      id: "CALTAM_Archive",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Tracks Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },

    CALTCM_Edit: {
      id: "CALTCM_Edit",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Tracks Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    CALTCM_RemoveTrack: {
      id: "CALTCM_RemoveTrack",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Tracks Context Menu - Remove Track",
      type: helpViewType.TOOLTIP,
    },
    CALTCM_RemoveRestrict: {
      id: "CALTCM_RemoveRestrict",
      module: helpType.CADMIN_LIBRARIES,
      label: "Content Admin Libraries Tracks Context Menu - Remove Restrict",
      type: helpViewType.TOOLTIP,
    },

    //ml admin users 
    ADUPWH: {
      id: "ADUPWH",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Preview Header",
      type: helpViewType.SINGLE,
    },

    ADUAB: {
      id: "ADUAB",
      module: helpType.ADMIN_USERS,
      label: "Admin Users App Bar",
      type: helpViewType.MULTIPLE,
    },

    ADUAM_Archive: {
      id: "ADUAM_Archive",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADUAM_Invite: {
      id: "ADUAM_Invite",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Action Menu - Invite",
      type: helpViewType.TOOLTIP,
    },
    ADUAM_Export: {
      id: "ADUAM_Export",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Action Menu - Export",
      type: helpViewType.TOOLTIP,
    },

    ADUCM_Edit: {
      id: "ADUCM_Edit",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Result Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    ADUCM_Archive: {
      id: "ADUCM_Archive",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Result Context Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADUCM_Invite: {
      id: "ADUCM_Invite",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Result Context Menu - Invite",
      type: helpViewType.TOOLTIP,
    },
    ADUCM_RemoveAbuseFlag: {
      id: "ADUCM_RemoveAbuseFlag",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Result Context Menu - Remove Abuse Flag",
      type: helpViewType.TOOLTIP,
    },

    ADU_AddEdit: {
      id: "ADU_AddEdit",
      module: helpType.ADMIN_USERS,
      label: "Admin Users Add/Edit",
      type: helpViewType.MULTIPLE,
    },

    // admin groups
    ADGPWH: {
      id: "ADGPWH",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Preview Header",
      type: helpViewType.SINGLE,
    },

    ADGAB: {
      id: "ADGAB",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups App Bar",
      type: helpViewType.MULTIPLE,
    },

    ADGAM_Archive: {
      id: "ADGAM_Archive",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Result Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADGAM_Restore: {
      id: "ADGAM_Restore",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Result Action Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    ADGAM_Export: {
      id: "ADGAM_Export",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Result Action Menu - Export",
      type: helpViewType.TOOLTIP,
    },

    ADGCM_Edit: {
      id: "ADGCM_Edit",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Result Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    ADGCM_Archive: {
      id: "ADGCM_Archive",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Result Context Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADGCM_Restore: {
      id: "ADGCM_Restore",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Result Context Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    ADG_AddEdit: {
      id: "ADG_AddEdit",
      module: helpType.ADMIN_GROUPS,
      label: "Admin Groups Add/Edit",
      type: helpViewType.MULTIPLE,
    },

    // admin roles 
    ADRPWH: {
      id: "ADRPWH",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Preview Header",
      type: helpViewType.SINGLE,
    },

    ADRAB: {
      id: "ADRAB",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles App Bar",
      type: helpViewType.MULTIPLE,
    },

    ADR_AddEdit: {
      id: "ADR_AddEdit",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Add/Edit",
      type: helpViewType.MULTIPLE,
    },

    ADRAM_Archive: {
      id: "ADRAM_Archive",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Result Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADRAM_Restore: {
      id: "ADRAM_Restore",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Result Action Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    ADRAM_Export: {
      id: "ADRAM_Export",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Result Action Menu - Export",
      type: helpViewType.TOOLTIP,
    },

    ADRCM_Edit: {
      id: "ADRCM_Edit",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Result Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    ADRCM_Archive: {
      id: "ADRCM_Archive",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Result Context Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADRCM_Restore: {
      id: "ADRCM_Restore",
      module: helpType.ADMIN_ROLES,
      label: "Admin Roles Result Context Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    // admin settings
    ADSPWH: {
      id: "ADSPWH",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Preview Header",
      type: helpViewType.SINGLE,
    },

    ADSAB: {
      id: "ADSAB",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings App Bar",
      type: helpViewType.MULTIPLE,
    },

    ADS_AddEdit: {
      id: "ADS_AddEdit",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Add/Edit",
      type: helpViewType.MULTIPLE,
    },

    ADSAM_Archive: {
      id: "ADSAM_Archive",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Result Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },

    ADSAM_Restore: {
      id: "ADSAM_Restore",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Result Action Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    ADSAM_ExportSelected: {
      id: "ADSAM_ExportSelected",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Result Action Menu - Export Selected",
      type: helpViewType.TOOLTIP,
    },

    ADSAM_Export: {
      id: "ADSAM_Export",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Result Action Menu - Export",
      type: helpViewType.TOOLTIP,
    },

    ADSCM_Edit: {
      id: "ADSCM_Edit",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Result Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    ADSCM_Archive: {
      id: "ADSCM_Archive",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Result Context Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADSCM_Restore: {
      id: "ADSCM_Restore",
      module: helpType.ADMIN_SETTINGS,
      label: "Admin Settings Result Context Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    // track ranking slider
    AD_TrackRanking: {
      id: "AD_TrackRanking",
      module: helpType.ADMIN_TRACK_RANKING,
      label: "Admin Track Ranking Slider Header",
      type: helpViewType.MULTIPLE,
    },

    // album ranking slider
    AD_AlbumRanking: {
      id: "AD_AlbumRanking",
      module: helpType.ADMIN_ALBUM_RANKING,
      label: "Admin Album Ranking Slider Header",
      type: helpViewType.MULTIPLE,
    },

    // admin activity limitations

    // ADALPWH: {
    //   id: "ADALPWH",
    //   module: helpType.ADMIN_ACTIVITY_LIMIT,
    //   label: "Admin Activity Limitations Settings Preview Header",
    //   type: helpViewType.SINGLE,
    // },

    ADALAB: {
      id: "ADALAB",
      module: helpType.ADMIN_ACTIVITY_LIMIT,
      label: "Admin Activity Limitations Settings App Bar",
      type: helpViewType.MULTIPLE,
    },
    ADAL_addEdit: {
      id: "ADAL_addEdit",
      module: helpType.ADMIN_ACTIVITY_LIMIT,
      label: "Admin Activity Limitations Settings - Add/Edit",
      type: helpViewType.MULTIPLE,
    },
    
    ADALAM_Archive: {
      id: "ADALAM_Archive",
      module: helpType.ADMIN_ACTIVITY_LIMIT,
      label: "Admin Activity Limitations Settings Result Action Menu - Archive",
      type: helpViewType.TOOLTIP,
    },

    ADALAM_Restore: {
      id: "ADALAM_Restore",
      module: helpType.ADMIN_ACTIVITY_LIMIT,
      label: "Admin Activity Limitations Settings Result Action Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    ADALCM_Edit: {
      id: "ADALCM_Edit",
      module: helpType.ADMIN_ACTIVITY_LIMIT,
      label: "Admin Activity Limitations Settings Result Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    ADALCM_Archive: {
      id: "ADALCM_Archive",
      module: helpType.ADMIN_ACTIVITY_LIMIT,
      label: "Admin Activity Limitations Settings Result Context Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADALCM_Restore: {
      id: "ADALCM_Restore",
      module: helpType.ADMIN_ACTIVITY_LIMIT,
      label: "Admin Activity Limitations Settings Result Context Menu - Restore",
      type: helpViewType.TOOLTIP,
    },

    // admin playlist category

    // ADPLCPWH: {
    //   id: "ADPLCPWH",
    //   module: helpType.ADMIN_PLAYLIST_CAT,
    //   label: "Admin Playlist Category Preview Header",
    //   type: helpViewType.SINGLE,
    // },

    ADPLCAB: {
      id: "ADPLCAB",
      module: helpType.ADMIN_PLAYLIST_CAT,
      label: "Admin Playlist Category App Bar",
      type: helpViewType.MULTIPLE,
    },

    ADPLC_addEdit: {
      id: "ADPLC_addEdit",
      module: helpType.ADMIN_PLAYLIST_CAT,
      label: "Admin Playlist Category - Add/Edit",
      type: helpViewType.MULTIPLE,
    },

    // ADPLCAM_Archive: {
    //   id: "ADPLCAM_Archive",
    //   module: helpType.ADMIN_PLAYLIST_CAT,
    //   label: "Admin Playlist Category Result Action Menu - Archive",
    //   type: helpViewType.TOOLTIP,
    // },

    // ADPLCAM_Restore: {
    //   id: "ADPLCAM_Restore",
    //   module: helpType.ADMIN_PLAYLIST_CAT,
    //   label: "Admin Playlist Category Result Action Menu - Restore",
    //   type: helpViewType.TOOLTIP,
    // },

    ADPLCCM_Edit: {
      id: "ADPLCCM_Edit",
      module: helpType.ADMIN_PLAYLIST_CAT,
      label: "Admin Playlist Category Result Context Menu - Edit",
      type: helpViewType.TOOLTIP,
    },
    ADPLCCM_Archive: {
      id: "ADPLCCM_Archive",
      module: helpType.ADMIN_PLAYLIST_CAT,
      label: "Admin Playlist Category Result Context Menu - Archive",
      type: helpViewType.TOOLTIP,
    },
    ADPLCCM_Restore: {
      id: "ADPLCCM_Restore",
      module: helpType.ADMIN_PLAYLIST_CAT,
      label: "Admin Playlist Category Result Context Menu - Restore",
      type: helpViewType.TOOLTIP,
    },
};
